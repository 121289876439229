import React from 'react'

export default function Select({ name, value, onChange, options }) {
	return (
		<div>
			<div className="inline-block">
				<select
					className="form-select border-0 rounded-lg shadow-md w-full"
					name="filterStatus"
					value={value}
					onChange={onChange}
				>
					<option value="">All Items</option>
					{options.map((val) => (
						<option key={val.value} value={val.value}>
							{val.show_as}
						</option>
					))}
				</select>
			</div>
		</div>
	)
}
