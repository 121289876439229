import React, { Component } from 'react'
import Loading from 'components/commons/Loading'
import ImageUpload from 'components/commons/ImageUpload'
import DateTimeSelect from 'components/commons/DateTimeSelect'
import FileUpload from '../formik/FileUpload'
import CampaignEmailPreview from 'components/formik/CampaignEmailPreview'
import { getRole } from 'libs/apis/smallGroup'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { Redirect } from 'react-router-dom'
import { Field } from 'formik'
import { TextInput } from 'components/commons/TextInput'
import { DescriptionBox } from 'components/commons/DescriptionBox'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import Label from 'components/commons/Label'
import SelectOptions from 'components/commons/SelectOptions'
import { Button } from 'components/commons/Button'

class CampaignEmailForm extends Component {
	state = {
		isRedirect: false,
		roles: [],
		filter_roles: [],
		checked: true,
		attachImg: this.props.values.image_url ? true : false,
		attachFile: this.props.values.attachment ? true : false,
		visibleToAll: this.props.values.visibleToAll,
	}

	async componentDidMount() {
		await this.handleGetRole()
		if (this.props.values.filter_roles.length !== 0) {
			this.setState({ ...this.state, visibleToAll: false })
		}
	}

	handleCancel = async (e) => {
		e.preventDefault()
		const res = await cancelConfirmation()
		if (res.value) {
			this.props.history.push('/campaign')
			this.setState({ isRedirect: true })
		}
	}

	handleOnDraft = (e) => {
		e.preventDefault()
		const { setFieldValue, handleSubmit } = this.props
		setFieldValue('is_draft', true)
		handleSubmit(e)
	}

	handleOnSubmit = () => {
		const { setFieldValue, handleSubmit } = this.props

		setFieldValue('filter_role[]', this.state.filter_roles)

		handleSubmit()
	}

	handleCheckSendAll = () => {
		this.setState({ ...this.state, visibleToAll: !this.state.visibleToAll })
	}

	handleOnDescription = (value) => {
		this.props.setFieldValue('content', value)
	}

	handleGetRole = async () => {
		let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
		if (rolesLocalStorage === null) {
			let res = await getRole()
			localStorage.setItem('roles', JSON.stringify(res.data.data))
			this.setState({ ...this.state, roles: res.data.data })
		} else {
			let roles = JSON.parse(localStorage.getItem('roles'))
			this.setState({ ...this.state, roles: roles })
		}
	}

	handleCheckFile = () => {
		this.setState({
			...this.state,
			attachFile: !this.state.attachFile,
		})
	}

	handleCheckImg = () => {
		this.setState({
			...this.state,
			attachImg: !this.state.attachImg,
		})
	}

	render() {
		const { values, errors, isSubmitting } = this.props
		const { isRedirect, roles } = this.state

		console.log(values)

		const genders = [
			{ value: 'ALL', label: 'Both (MALE & FEMALE)' },
			{ value: 'F', label: 'FEMALE' },
			{ value: 'M', label: 'MALE' },
		]

		if (isRedirect) {
			return <Redirect to="/campaign/" />
		}

		return (
			<div className="flex md:-mx-2 flex-wrap">
				<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
					<div className="bg-white p-6 shadow-md">
						<div className="mb-6">
							<Field
								name="subject"
								as={TextInput}
								label={'SUBJECT'}
								errors={errors.subject}
							/>
						</div>
						<div className="mb-6">
							<Field
								name="content"
								label={'CONTENT'}
								as={DescriptionBox}
								onChange={this.handleOnDescription}
								errors={errors.content}
							/>
						</div>

						<div className="mb-6 flex-col">
							<div>
								<Label text={'ATTACH IMAGE'} />
							</div>

							<div className="flex ml-2">
								<div className="mb-5 mt-3">
									<InputCheckBox
										checked={this.state.attachImg}
										name={'checkimg'}
										label={'YES'}
										onChange={this.handleCheckImg}
									/>
								</div>

								<div className="mb-5 mt-3 ml-48">
									<InputCheckBox
										checked={!this.state.attachImg}
										name={'checkimg'}
										label={'NO'}
										onChange={this.handleCheckImg}
									/>
								</div>
							</div>
							{this.state.attachImg ? (
								<div>
									<div className="mb-6">
										<Label text={'UPLOAD IMAGE'} />
										<Field name="image_url" component={ImageUpload} />
									</div>
								</div>
							) : (
								<div />
							)}
						</div>

						<div className="mb-6 flex-col">
							<div>
								<Label text={'ATTACH FILE'} />
							</div>

							<div className="flex flex-row">
								<div className="flex ml-2">
									<div className="mb-5 mt-3">
										<InputCheckBox
											checked={this.state.attachFile}
											name={'checkimg'}
											label={'YES'}
											onChange={this.handleCheckFile}
										/>
									</div>

									<div className="mb-5 mt-3 ml-48">
										<InputCheckBox
											checked={!this.state.attachFile}
											name={'checkimg'}
											label={'NO'}
											onChange={this.handleCheckFile}
										/>
									</div>
								</div>
							</div>

							{this.state.attachFile ? (
								<div>
									<div className="mb-6">
										<Label text={'UPLOAD FILE'} />
										<Field name="attachment" component={FileUpload} />
									</div>
								</div>
							) : null}
						</div>

						<div>
							<Label text={'RECIPIENTS'} />
							<div className="flex mb-8 ml-2">
								<div className="flex-col">
									{roles
										.slice(0, roles.length / 2 + 1)
										.map((val, idx) => (
											<div key={idx} className="mt-2">
												<Field
													as={InputCheckBox}
													name="filter_roles"
													className="mt-4 mr-2"
													type="checkbox"
													value={val.role_id}
													checked={this.props.values.filter_roles.includes(
														val.role_id.toString()
													)}
													label={val.show_as}
												/>
											</div>
										))}
								</div>
								<div className="flex-col ml-40">
									{roles.slice(roles.length / 2 + 1).map((val, idx) => (
										<div key={idx} className="mt-2">
											<Field
												as={InputCheckBox}
												name="filter_roles"
												className="mt-4 mr-2"
												type="checkbox"
												value={val.role_id}
												checked={this.props.values.filter_roles.includes(
													val.role_id.toString()
												)}
												label={val.show_as}
											/>
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="mb-6 flex">
							<div className="w-full mr-5">
								<Field
									as={SelectOptions}
									name="filter_gender"
									label={'RECIPIENTS GENDER'}
									options={genders}
									value={values.filter_gender}
									errors={errors.filter_gender}
								/>
							</div>
							<div className="w-full">
								<Field
									as={SelectOptions}
									name="filter_marital_status"
									label={'RECIPIENTS MARIAGE STATUS'}
									options={genders}
									value={values.filter_marital_status}
									errors={errors.filter_marital_status}
								/>
							</div>
						</div>

						<div className="w-3/6">
							<Label text={'SCHEDULE'} />
							<Field name="schedule" component={DateTimeSelect} />
						</div>

						<div className="flex mt-8">
							<div className="w-1/2">
								<Button type="cancel" handleClick={this.handleCancel}>
									{isSubmitting ? <Loading secondary /> : 'Cancel'}
								</Button>
							</div>
							<div className="w-1/2">
								<div className="flex justify-end">
									<Button
										handleClick={this.handleOnDraft}
										type="secondary"
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											<Loading primary />
										) : (
											'Save As Draft'
										)}
									</Button>

									<Button
										handleClick={this.handleOnSubmit}
										type="primary"
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading secondary /> : 'Publish'}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-1/3 w-full px-2">
					<CampaignEmailPreview {...this.props} />
				</div>
			</div>
		)
	}
}

export default CampaignEmailForm
