import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { smallGroupCategories, smallGroupStatuses, smallGroupRegencies } from 'libs/apis/smallGroup'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import SmallGroupFormik from 'components/formik/SmallGroupFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import InputRadioButton from 'components/commons/InputRadioButton'

function CreateSmallGroup(props) {
	const [tab, setTab] = useState(true)
	const [categories, setCategories] = useState([])
	const [statuses, setStatuses] = useState([])
	const [regencies, setRegencies] = useState([])
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		const fetchData = async () => {
			try {
				const categories = await smallGroupCategories()
				const statuses = await smallGroupStatuses()
				const regencies = await smallGroupRegencies()

				setCategories(categories.data.data)
				setStatuses(statuses.data.data)
				setRegencies(regencies.data.data)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						setIsRedirect(true)
					} else {
						console.log(error)
						setErrorMessage(
							'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
						)
					}
				}
			}
		}

		fetchData()
	}, [])

	const handleOnTab = () => {
		setTab(!tab)
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create Group' }]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Small Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="Create Group" breads={breads} />

			<div className="flex bg-white shadow-md pl-4 pt-4">
				<div className="w-1/2">
					<InputRadioButton
						value={tab}
						label="MULTIPLICATION"
						onChange={handleOnTab}
						checked={tab}
					/>
				</div>
				<div className="w-1/2">
					<InputRadioButton
						value={!tab}
						label="NEW GROUP"
						onChange={handleOnTab}
						checked={!tab}
					/>
				</div>
			</div>

			<div>
				{errorMessage && <div className="text-center">{errorMessage}</div>}

				{tab ? (
					<SmallGroupFormik
						{...props}
						categories={categories}
						statuses={statuses}
						regencies={regencies}
						multi={true}
					/>
				) : (
					<SmallGroupFormik
						{...props}
						categories={categories}
						statuses={statuses}
						regencies={regencies}
						multi={false}
					/>
				)}
			</div>
		</div>
	)
}

export default CreateSmallGroup
