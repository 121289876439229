import React, { useState } from 'react'
import Modal from 'react-modal'
import Flatpickr from 'react-flatpickr'
import styled from 'styled-components'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '85%',
		height: '29%',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

const ButtonFilter = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 0 -1px rgba(0, 0, 0, 0.06);
	border: ;
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	position: absolute;
	top: 0;
	left: 80px;

	:focus {
		outline: none;
	}
`

const ButtonFilterSG = styled.div`
	background-color: #fff;
	box-shadow: 0 8px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border: ;
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	z-index: 10;
	:focus {
		outline: none;
	}
`

function AppointmentsFilter({ filter, handleClearFilter, handleApplyFilter, handleSelectFilter }) {
	const [tab, setTab] = useState('Period')
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('Period')
		handleClearFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
	}

	const periodTab = tab === 'Period'
	const typeTab = tab === 'Type'

	const types = ['Type 1', 'Type 2', 'Type 3']

	return (
		<div>
			<div className="flex flex-row relative">
				<ButtonFilter>X</ButtonFilter>
				<ButtonFilterSG>Filter2</ButtonFilterSG>
			</div>

			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Appointment Filter Modal"
				onRequestClose={handleToggleModal}
				appElement={document.getElementById('root')}
			>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center text-primary pl-2 text-xl font-bold active:bg-red-700">
						Filter
					</div>
					<div
						className="flex justify-center text-primary pr-2 cursor-pointer text-xl font-bold"
						onClick={handleToggleModal}
					>
						X
					</div>
				</div>
				<div className="flex bg-gray-200 pb-4">
					<div className="flex flex-col justify-between">
						<input
							className={`${
								tab === 'Period'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'Period'}
							onClick={handleOnTab}
						/>
						<input
							className={`${
								tab === 'Type'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'Type'}
							onClick={handleOnTab}
						/>
					</div>
					<div className="border-2 blue mx-4 mt-2 h-auto" />
					<div className="flex flex-wrap justify-arround pr-2">
						{periodTab && (
							<div className="flex pt-2 w-full">
								<div className="inline-block w-1/2">
									<div className="shadow-md flex justify-between rounded-lg bg-white px-3 py-2 mr-6">
										<div className="input-group-prepend">From</div>
										<Flatpickr
											className="w-full text-center"
											options={{
												dateFormat: 'YYYY-MM-DD',
												altInput: true,
												altFormat: 'd M Y',
												enableTime: false,
												minDate: 'today',
											}}
										/>
										<div className="input-group-prepend">
											<i className="far fa-calendar-alt" />
										</div>
									</div>
								</div>
								<div className="inline-block w-1/2">
									<div className="shadow-md flex justify-between rounded-lg bg-white px-3 py-2 mr-6">
										<div className="input-group-prepend">To</div>
										<Flatpickr
											className="w-full text-center"
											options={{
												dateFormat: 'YYYY-MM-DD',
												altInput: true,
												altFormat: 'd M Y',
												enableTime: false,
												minDate: 'today',
											}}
										/>
										<div className="input-group-prepend">
											<i className="far fa-calendar-alt" />
										</div>
									</div>
								</div>
							</div>
						)}

						{typeTab &&
							types.map((type) => (
								<div className="pr-2">
									<input
										className={`${
											filter.types.includes(type)
												? 'bg-white'
												: 'bg-gray-200'
										} py-2 m-2 w-40 border-secondary border-2 rounded-lg`}
										type="button"
										name="types"
										value={type}
										onClick={handleSelectFilter}
									/>
								</div>
							))}
					</div>
				</div>

				<div className="flex justify-between">
					<div
						className="flex w-1/2 bg-grey-300 h-10 inline-block cursor-pointer justify-center"
						onClick={handleClearFilter}
					>
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Clear Filter
						</span>
					</div>
					<div
						className="flex w-1/2 bg-primary h-10 inline-block cursor-pointer justify-center"
						onClick={handleApplyFilter}
					>
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Apply Filter
						</span>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default AppointmentsFilter
