import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import AppointmentsExport from 'components/smallGroup/AppointmentsExport'

function AppointmentsTable({ list }) {
	return (
		<div className="bg-white shadow-md rounded">
			<table className="w-full">
				<thead className="bt-blueSecondary py-4 border-2 border-solid">
					<tr>
						<th className="px-4 py-4">
							TYPE
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
						<th className="px-4 py-4">
							TITLE
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
						<th className="px-4 py-4">
							DATE
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
						<th className="px-4 py-4">
							TIME
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
						<th className="px-4 py-4">
							CREATED BY
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
						<th className="px-4 py-4">
							ATTENDEE
							<i className="fas fa-sort sortby cursor-pointer ml-1" />
						</th>
					</tr>
				</thead>
				<tbody className="border-2 border-solid">
					{list.map((val) => (
						<AppointmentsRow
							key={val.id}
							id={val.id}
							type={val.type}
							title={val.title}
							date={val.date}
							time={val.time}
							createdBy={val.createdBy}
							attendee={val.attendee}
						/>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default AppointmentsTable

function AppointmentsRow(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}
	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const { id, type, title, date, time, createdBy, attendee, handleDelete } = props

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
		dropDownOpen ? 'block' : 'hidden'
	}`

	return (
		<tr className="border border-solid bt-blueSecondary text-center">
			<td className="px-2 py-4">{type}</td>
			<td className="px-2 py-4">{title}</td>
			<td className="px-2 py-4">{date}</td>
			<td className="px-2 py-4">{time}</td>
			<td className="px-4 py-4">{createdBy}</td>
			<td className="px-4 py-4">
				<span className="font-bold">{attendee[0]}</span> / <span>{attendee[1]}</span>
			</td>
			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div ref={node} className={styleDropDownOpen}>
						<ul className="bg-white">
							<li>
								<Link
									className="px-4 py-2 block hover:bg-blueSecondary text-left"
									to={`/smallgroup/attendance-detail/${id}`}
								>
									View Details
								</Link>
							</li>
							<li className="px-4 py-2 block hover:bg-blueSecondary text-left">
								<AppointmentsExport />
							</li>
							<li
								className="px-4 py-2 block hover:bg-blueSecondary text-left"
								onClick={() => handleDelete(id)}
							>
								Delete
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
