import React, { useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import ButtonExport from 'components/commons/ButtonExport'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '82%',
		height: '54%',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

const ButtonFilter = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 0 -1px rgba(0, 0, 0, 0.06);
	border: ;
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	position: absolute;
	top: 0;
	left: 80px;

	:focus {
		outline: none;
	}
`

const ButtonFilterSG = styled.div`
	background-color: #fff;
	box-shadow: 0 8px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border: ;
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	z-index: 10;
	:focus {
		outline: none;
	}
`

export default function SmallGroupFilter({
	handleSelectFilter,
	filter,
	handleApplyFilter,
	handleClearFilter,
}) {
	const [tab, setTab] = useState('locations')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('locations')
		handleClearFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const locations = [
		'Jakarta Barat',
		'Jakarta Pusat',
		'Jakarta Selatan',
		'Jakarta Timur',
		'Jakarta Utara',
		'Bogor',
		'Depok',
		'Tanggerang',
		'Bekasi',
	]
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
	const categories = ['General', 'Married Couple']
	const sgLevels = ['Small Group', 'Head Facilitator', 'Facilitator']
	const campuses = ['Pondok Indah Mall', 'The Kasablanka', 'Upperroom Jakarta']
	const locationTab = tab === 'locations'
	const dayTab = tab === 'day'
	const categoryTab = tab === 'category'
	const sgLevelTab = tab === 'SG Level'
	const campusTab = tab === 'campus'

	return (
		<div>
			<div className="flex flex-row relative">
				<ButtonFilter>X</ButtonFilter>
				<ButtonFilterSG>Filter2</ButtonFilterSG>
			</div>

			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Small Group Filter Modal"
				appElement={document.getElementById('root')}
				onRequestClose={handleToggleModal}
			>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center text-primary pl-2 text-xl font-bold active:bg-red-700">
						Filter
					</div>
					<div
						className="flex justify-center text-primary pr-2 cursor-pointer text-xl font-bold"
						onClick={handleToggleModal}
					>
						X
					</div>
				</div>
				<div className="flex bg-gray-200 pb-4">
					<div>
						<div className="flex flex-col justify-between">
							<input
								className={`${
									tab === 'locations'
										? 'bg-white shadow-md'
										: 'bg-gray-200 border-secondary border-2'
								} py-2 m-2 w-32 rounded-r-lg`}
								type="button"
								value={'locations'}
								onClick={handleOnTab}
							/>
							<input
								className={`${
									tab === 'day'
										? 'bg-white shadow-md'
										: 'bg-gray-200 border-secondary border-2'
								} py-2 m-2 w-32 rounded-r-lg`}
								type="button"
								value={'day'}
								onClick={handleOnTab}
							/>
							<input
								className={`${
									tab === 'category'
										? 'bg-white shadow-md'
										: 'bg-gray-200 border-secondary border-2'
								} py-2 m-2 w-32 rounded-r-lg`}
								type="button"
								value={'category'}
								onClick={handleOnTab}
							/>
							<input
								className={`${
									tab === 'SG Level'
										? 'bg-white shadow-md'
										: 'bg-gray-200 border-secondary border-2'
								} py-2 m-2 w-32 rounded-r-lg`}
								type="button"
								value={'SG Level'}
								onClick={handleOnTab}
							/>
							<input
								className={`${
									tab === 'campus'
										? 'bg-white shadow-md'
										: 'bg-gray-200 border-secondary border-2'
								} py-2 m-2 w-32 rounded-r-lg`}
								type="button"
								value={'campus'}
								onClick={handleOnTab}
							/>
						</div>
					</div>
					<div className="border-2 blue mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround pr-2">
							{locationTab &&
								locations.map((location) => (
									<div className="pr-2">
										<input
											className={`${
												filter.location.includes(location)
													? 'bg-white'
													: 'bg-gray-200'
											} py-2 m-2 p-2 w-40 border-secondary border-2 rounded-lg`}
											name="location"
											type="button"
											value={location}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{dayTab &&
								days.map((day) => (
									<div className="pr-2">
										<input
											className={`${
												filter.day.includes(day)
													? 'bg-white'
													: 'bg-gray-200'
											} py-2 m-2 w-40 border-secondary border-2 rounded-lg`}
											type="button"
											name="day"
											value={day}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{categoryTab &&
								categories.map((category) => (
									<div className="pr-2">
										<input
											className={`${
												filter.category.includes(category)
													? 'bg-white'
													: 'bg-gray-200'
											} py-2 m-2 w-40 border-secondary border-2 rounded-lg`}
											type="button"
											name="category"
											value={category}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{sgLevelTab &&
								sgLevels.map((sgLevel) => (
									<div className="pr-2">
										<input
											className={`${
												filter.sgLevel.includes(sgLevel)
													? 'bg-white'
													: 'bg-gray-200'
											} py-2 m-2 w-40 border-secondary border-2 rounded-lg`}
											type="button"
											name="sgLevel"
											value={sgLevel}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{campusTab &&
								campuses.map((campus) => (
									<div className="pr-2">
										<input
											className={`${
												filter.campus.includes(campus)
													? 'bg-white'
													: 'bg-gray-200'
											} py-2 m-2 w-40 border-secondary border-2 rounded-lg`}
											type="button"
											name="campus"
											value={campus}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
						</div>
					</div>
				</div>
				<div className="flex justify-between">
					<div
						className="flex w-1/2 bg-grey-300 h-10 inline-block cursor-pointer justify-center"
						onClick={handleClearFilters}
					>
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Clear Filter
						</span>
					</div>
					<div
						className="flex w-1/2 bg-primary h-10 inline-block cursor-pointer justify-center"
						onClick={handleApplyFilter}
					>
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Apply Filter
						</span>
					</div>
				</div>
			</Modal>
		</div>
	)
}
