import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { getRole } from 'libs/apis/smallGroup'
import { uploadFile } from 'libs/apis/file'
import alert from 'services/alert'
import { FileInput } from 'components/commons/FileInput'
import { Button } from 'components/commons/Button'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import Loading from 'components/commons/Loading'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '390px',
		width: '550px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}
export default function FileUpload(props) {
	const [roles, setRoles] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])
	const [selectedFile, setSelectedFile] = useState([])
	const [fileName, setFileName] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [visibleToAll, setVisibleToAll] = useState(false)

	useEffect(() => {
		const handleGetRole = async () => {
			let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
			if (rolesLocalStorage === null) {
				let res = await getRole()
				localStorage.setItem('roles', JSON.stringify(res.data.data))
				setRoles(res.data.data)
			} else {
				let roles = JSON.parse(localStorage.getItem('roles'))
				setRoles(roles)
			}
		}

		handleGetRole()
	}, [])

	const handleCheckbox = (e) => {
		if (!e.target.checked) {
			setVisibleToAll(false)
			setSelectedRoles(removeItem(selectedRoles, parseInt(e.target.value)))
		} else {
			setVisibleToAll(false)
			setSelectedRoles([...selectedRoles, parseInt(e.target.value)])
		}
	}

	const handleCheckSendAll = () => {
		let selectedRoles = roles.map((role) => role.role_id)

		if (visibleToAll) {
			setVisibleToAll(false)
			setSelectedRoles([])
			clearCheckBoxList(roles)
		} else {
			setVisibleToAll(true)
			setSelectedRoles(selectedRoles)
			clearCheckBoxList(roles)
		}
	}

	const handleOnChange = (event) => {
		setErrorMessage('')

		let file = event.target.files[0]

		if (file) {
			let validateFile = vaidateSize(file)
			setSelectedFile(validateFile)
			setFileName(file.name)
		}
	}

	const handleSubmit = async () => {
		const formdata = new FormData()
		formdata.append('file', selectedFile)

		if (selectedRoles.length !== 0) {
			setVisibleToAll(false)
			for (let i = 0; i < selectedRoles.length; i++) {
				formdata.append('filter_role[]', selectedRoles[i])
			}

			handleUploadFile(formdata)
		}

		if (visibleToAll || selectedRoles.length === 0) {
			for (let i = 0; i < roles.length; i++) {
				formdata.append('filter_role[]', roles[i].role_id)
			}
			handleUploadFile(formdata)
		}
	}

	/* ------ HELPER -------- */
	const togleModal = () => {
		clearCheckBoxList(roles)
		setModalIsOpen(!modalIsOpen)
		setSelectedFile(null)
		setSelectedRoles([])
		setErrorMessage('')
		setFileName('')
		setVisibleToAll(false)
	}

	const removeItem = (arr, value) => {
		return arr.filter(function (ele) {
			return ele !== value
		})
	}

	const clearCheckBoxList = (array) => {
		for (let i = 0; i < array.length; i++) {
			array[i].is_checked = 0
		}
	}

	const vaidateSize = (file) => {
		let max = 20971520
		if (file === null) {
			setErrorMessage('File cannot be empty')
			return
		} else {
			if (file.size > max) {
				setErrorMessage('Your file size is too big')
				return
			}
			return file
		}
	}

	const handleUploadFile = async (formdata) => {
		setIsLoading(true)
		let res = await uploadFile(formdata)
		if (res.data.api_status) {
			alert.succeed(`Success`, `Your file has been uploaded`)

			togleModal()

			props.handleLoadData()

			setIsLoading(false)
		} else {
			alert.failed(`Ooops`, `Cannot upload your file`)

			setIsLoading(false)
		}
	}
	/* --------- END --------- */

	return (
		<div>
			<div onClick={togleModal}>Upload File</div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Example Modal"
				appElement={document.getElementById('root')}
			>
				<h2 className="font-bold mb-2">UPLOAD FILE</h2>
				<hr className="border-2 border-blueSecondary" />
				<div className="flex mt-3">
					<FileInput handleOnChange={handleOnChange} fileName={fileName} />
				</div>

				<div className="float-right ">
					<p className="mt-3 text-red-400 text-xs">Maximum file size is 20 MB</p>
				</div>
				<div className="float-left ">
					<p className="mt-3 text-red-400 text-xs">{errorMessage}</p>
				</div>

				<hr className="border-2 border-blueSecondary mt-10" />

				<h2 className="font-bold ml-5 mb-2 mt-2">VISIBLE TO:</h2>
				<div className="flex justify-center">
					<div className="flex-col">
						<InputCheckBox
							value={visibleToAll}
							onChange={handleCheckSendAll}
							checked={visibleToAll || roles.length === selectedRoles.length}
							label="All"
							className="mb-4"
						/>

						{roles.slice(0, roles.length / 2).map((val, idx) => (
							<InputCheckBox
								id={val.role_id}
								key={idx}
								checked={selectedRoles.includes(val.role_id)}
								value={val.role_id}
								onChange={handleCheckbox}
								label={val.show_as}
								className="mb-4"
							/>
						))}
					</div>
					<div className="flex-col ml-12">
						{roles.slice(roles.length / 2).map((val, idx) => (
							<InputCheckBox
								id={val.role_id}
								key={idx}
								value={val.role_id}
								onChange={handleCheckbox}
								checked={selectedRoles.includes(val.role_id)}
								label={val.show_as}
								className="mb-4"
							/>
						))}
					</div>
				</div>

				<div className="flex justify-center mt-4">
					<Button type="cancel" handleClick={togleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" handleClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
