import styled from 'styled-components'

export const ContainerWrapper = styled.div`
	background-color: white;
	padding: 1.5rem 1.5rem 1.5rem 1.5rem;
	-webkit-box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.14);
	box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.14);
`

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1rem;
`
