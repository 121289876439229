import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import { viewRequest, viewRequestExport } from 'libs/apis/smallGroup'
import { errorMessage500 } from 'libs/utils/messages'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import ViewRequestTable from 'components/ViewRequestList/ViewRequestTable'
import PaginationPage from 'components/commons/PaginationPage'
import Loading from 'components/commons/Loading'
import Searchbar from 'components/commons/Searchbar'
import { Button } from 'components/commons/Button'

class ViewRequest extends Component {
	state = {
		isLoading: false,
		errorMessage: false,
		isRedirect: false,
		filterKeyword: '',
		orderBy: '',
		orderDir: '',
		data: [],
		currentPage: 0,
		lastPage: 1,
		isExportSubmit: false,
		name: '',
	}

	componentDidMount() {
		this.LoadData(this.state.filterKeyword)
	}

	LoadData = (value) => {
		this.setState({ isLoading: true }, async () => {
			try {
				const parsed = queryString.parse(this.props.location.search)
				const page = parsed.page || 1
				const id = this.props.match.params.id
				const { orderBy, orderDir } = this.state

				const response = await viewRequest(id, page, value, orderBy, orderDir)

				if (response.data.api_status) {
					this.setState({
						currentPage: response.data.data.current_page,
						lastPage: response.data.data.last_page,
						data: response.data.data.data,
						name: response.data.data.small_group_name,
						isLoading: false,
					})
				} else {
					this.setState({ isLoading: false })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: errorMessage500 })
					}
				}
			}
		})
	}

	handleOnKeyword = async (event) => {
		this.props.history.push(`${this.props.location.pathname}?page=1`)
		this.LoadData(event.target.value)
		this.setState({ filterKeyword: event.target.value })
	}

	handleExport = async () => {
		const { filterKeyword, orderBy, orderDir } = this.state
		this.setState({ isExportSubmit: true })
		const response = await viewRequestExport(
			this.props.match.params.id,
			filterKeyword,
			orderBy,
			orderDir
		)
		if (response.data.api_status) {
			const link = document.createElement('a')
			link.href = response.data.data
			document.body.appendChild(link)
			link.click()
		}

		this.setState({ isExportSubmit: false })
	}

	handleSort = (sort) => {
		const { orderBy, orderDir, filterKeyword } = this.state

		if (orderBy !== sort) {
			this.setState({ orderBy: sort, orderDir: 'asc' }, () => this.LoadData(filterKeyword))
		} else {
			if (orderDir === 'asc') {
				this.setState({ orderBy: sort, orderDir: 'desc' }, () =>
					this.LoadData(filterKeyword)
				)
			} else if (orderDir === 'desc') {
				this.setState({ orderBy: sort, orderDir: 'asc' }, () =>
					this.LoadData(filterKeyword)
				)
			}
		}
	}

	renderData = () => {
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		const { data, currentPage, lastPage } = this.state

		return (
			<div>
				<ViewRequestTable list={data} handleSort={this.handleSort} />
				<PaginationPage
					currentPage={currentPage}
					lastPage={lastPage}
					activeLink={active}
					length={data.length}
					url={location.pathname}
				/>
			</div>
		)
	}

	render() {
		const breads = [
			{ url: '/small-group/list', title: 'List of All Groups' },
			{ url: '', title: 'View Requests' },
		]
		const church = JSON.parse(localStorage.getItem('church'))
		const {
			data,
			isLoading,
			errorMessage,
			isExportSubmit,
			filterKeyword,
			name,
			isRedirect,
		} = this.state

		const shouldDisplayLoading = isLoading && !errorMessage
		const shouldDisplayData = !isLoading && !errorMessage && data.length > 0
		const shouldDisplayNoData = !isLoading && !errorMessage && data.length === 0
		const shouldDisplayError = !isLoading && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{church.church_name} - View Request</title>
				</Helmet>
				<PageBreadcrumbs
					icon={SGIcon}
					title={`View Requests: ${name}`}
					breads={breads}
				/>
				<div className="flex">
					<Searchbar
						filterKeyword={filterKeyword}
						handleOnSearch={this.handleOnKeyword}
					/>

					<div className="inline-block ml-auto">
						<Button type="export" handleClick={this.handleExport}>
							{isExportSubmit ? <Loading secondary /> : 'Export'}
						</Button>
					</div>
				</div>
				{shouldDisplayLoading && <Loading />}
				{shouldDisplayData && this.renderData()}
				{shouldDisplayNoData && <div className="text-center">No Data Available</div>}
				{shouldDisplayError && <div className="text-center">{errorMessage}</div>}
			</div>
		)
	}
}

export default ViewRequest
