import React from 'react'
import ReactQuill from 'react-quill'

export function DescriptionBox(props, { label, size, handleChange, value, errors = props.errors }) {
	const btnSize = {
		small: 'w-64 mt-3',
		medium: 'w-3/6 mt-3',
		large: 'w-full mt-3',
	}

	return (
		<div>
			{props.label || label ? (
				<label className="ml-1 block text-gray-700 text-sm font-bold mb-2">
					{label || props.label}
				</label>
			) : null}
			<ReactQuill
				className={btnSize[size]}
				value={value || '' || props.value}
				onChange={handleChange || props.onChange}
			/>
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}
