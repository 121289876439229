import React from 'react'

const SmallGroupReportCard = ({ label, value }) => {
	return (
		<div className="mr-2 rounded-lg border-2 border-greySecondary flex flex-col justify-around">
			<div className="font-bold text-5xl text-center">{value}</div>
			<div className="bg-greySecondary text-center font-bold py-6">{label}</div>
		</div>
	)
}

export default SmallGroupReportCard
