import React from 'react'
import { TextInput } from 'components/commons/TextInput'
import { Button } from 'components/commons/Button'
import { DescriptionBox } from 'components/commons/DescriptionBox'
import { DropdownAsync } from 'components/commons/DropdownAsync'
import { Dropdown } from 'components/commons/DropdownItem'
import { UploadImage } from 'components/commons/UploadImage'
import Loading from 'components/commons/Loading'
import { ContainerWrapper, ButtonWrapper } from 'screens/ministry/Styles.js'

function MinistryForm(props) {
	const {
		ministryName,
		handleMinistryName,
		ministryHead,
		handleOnLeaderChange,
		handleOnLoadLeaders,
		ministryDescription,
		handleDescription,
		handleChangeSelect,
		imagePreview,
		handleFileChange,
		handleCancel,
		isLoading,
		ministryStatus,
		handleSubmit,
		statuses,
	} = props
	return (
		<ContainerWrapper>
			<div className="mb-6 w-1/2">
				<TextInput
					label="MINISTRY NAME"
					value={ministryName}
					onChange={handleMinistryName}
					size="medium"
				/>
			</div>

			<div className="mb-6 w-1/2">
				<DropdownAsync
					label="MINISTRY HEAD"
					onChange={handleOnLeaderChange}
					value={ministryHead}
					loadOption={handleOnLoadLeaders}
					isMulti={true}
				/>
			</div>

			<div className="mb-6 w-1/2">
				<DescriptionBox
					label="DESCRIPTION"
					value={ministryDescription}
					handleChange={handleDescription}
				/>
			</div>

			<div className="mb-6 w-1/2">
				<Dropdown
					label="RECRUITMENT STATUS"
					options={statuses}
					value={ministryStatus}
					handleChange={handleChangeSelect}
				/>
			</div>

			<UploadImage imageUrl={imagePreview} handleFileChange={handleFileChange} />

			<ButtonWrapper>
				<Button type="cancel" handleClick={handleCancel}>
					Cancel
				</Button>
				<Button type="primary" handleClick={handleSubmit}>
					{isLoading ? <Loading secondary /> : 'Publish'}
				</Button>
			</ButtonWrapper>
		</ContainerWrapper>
	)
}

export default MinistryForm
