import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { Button } from 'components/commons/Button'
import TransferMember from 'screens/smallGroup/TransferMember'
import { RoleContainer, RoleRibbon } from './styles'
import { SmallGroupHeader } from './styles'

function ViewSmallGroup(props) {
	const [openCard, setOpenCard] = useState({
		members: true,
		probations: false,
		requests: false,
		probations2: false,
	})
	const [isRedirect, setIsRedirect] = useState(false)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const handleTogle = (event) => {
		setOpenCard({ ...openCard, [event.target.id]: !openCard[event.target.id] })
	}

	const handleViewMember = (params) => {
		props.history.push('/small-group/view-member/:sgId/:id')
	}

	const handleViewAttendance = React.useCallback((params) => {
		props.history.push('/small-group/attendance/:id')
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const displayLoading = isLoadingData && !errorMessage
	const displayError = !isLoadingData && errorMessage
	const displayData = !isLoadingData && !errorMessage
	const breads = [
		{ url: '/small-group/list', title: 'List of All Groups' },
		{ url: '', title: 'View Group' },
	]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="View Group" breads={breads} />

			<SmallGroupHeader>
				<Button type={'tertiary'} handleClick={() => handleViewAttendance()}>
					Appointments
				</Button>
				<Button style="ml-6" type={'tertiary'}>
					Export
				</Button>
				<TransferMember />
			</SmallGroupHeader>

			{/* {displayLoading && <Loading />} */}
			{displayData && (
				<div className="flex mb-4 mt-4">
					<div className="w-1/4">
						{/* SMALL GROUP DETAILS */}
						<div className="bg-white shadow-md rounded p-4">
							<div className="font-bold mb-2">Small Group Name</div>

							<SGLeaderDetails />

							<hr className="w-full border-2 border-blueSecondary" />

							<SGDetail />

							<hr className="w-full border-2 border-blueSecondary mt-2" />

							<SGMultiplicationDetails />
						</div>
					</div>

					<div className="w-3/4 ml-4">
						<SGMemberProbationRequest
							openCard={openCard}
							handleTogle={handleTogle}
							handleViewMember={handleViewMember}
						/>
					</div>
				</div>
			)}
			{displayError && <div className="align-center">{errorMessage}</div>}
		</div>
	)
}

export default ViewSmallGroup

const SGLeaderDetails = () => {
	return (
		<>
			<div className="font-bold mb-2 mt-3">LEADER DETAILS</div>

			<div>
				<label className="text-sm font-bold">Small Group Leader</label>
				<p className="text-sm">Name 1</p>
				<p className="text-sm mb-4">Name 2</p>
			</div>
		</>
	)
}

const SGDetail = () => {
	return (
		<>
			<div className="font-bold mb-2 mt-3">SMALL GROUP DETAIL</div>

			<div>
				<label className="text-sm font-bold">Campus</label>
				<p className="text-sm mb-4">Campus A</p>
			</div>

			<div>
				<label className="text-sm font-bold">Level</label>
				<p className="text-sm mb-4">Small Group</p>
			</div>

			<div>
				<label className="text-sm font-bold">Category</label>
				<p className="text-sm mb-4">General</p>
			</div>

			<div>
				<label className="text-sm font-bold">Level</label>
				<p className="text-sm mb-4">Small Group</p>
			</div>

			<div>
				<label className="text-sm font-bold">Age Range</label>
				<p className="text-sm mb-4">23-27</p>
			</div>

			<div>
				<label className="text-sm font-bold">Full Address</label>
				<p className="text-sm mb-4">Jl. Erlangga 1, No.8, Jakarta Selatan 12345</p>
			</div>

			<div>
				<label className="text-sm font-bold">Short Address</label>
				<p className="text-sm mb-4">Erlangga</p>
			</div>

			<div>
				<label className="text-sm font-bold">Date | Time</label>
				<p className="text-sm mb-4">Thursday | 18.00</p>
			</div>

			<div>
				<label className="text-sm font-bold">Status</label>
				<p className="text-sm mb-4">Open</p>
			</div>

			<div>
				<label className="text-sm font-bold">Established in</label>
				<p className="text-sm mb-4">February 2015</p>
			</div>

			<div>
				<label className="text-sm font-bold">Small Group Description</label>
				<ReactQuill
					modules={{ toolbar: false }}
					className="text-sm h-24 -ml-4 h-auto"
					theme={null}
					value={''}
					formats={null}
				/>
			</div>
		</>
	)
}

const SGMultiplicationDetails = () => {
	return (
		<>
			<div className="font-bold mb-2 mt-3">MULTIPLICATION DETAIL</div>
			<div>
				<label className="text-sm font-bold">Groups</label>
				<ul>
					<li>Karawaci 3 | 9 September 2017</li>
					<li>Gading Serpong | 13 July 2017</li>
				</ul>
			</div>

			<hr className="border-2 border-blueSecondary mt-2" />
			<div className="font-bold mb-2 mt-3">SMALL GROUP PARENT DETAIL</div>
			<div>
				<label className="text-sm font-bold">Facilitator</label>
				<p className="text-sm">Pevita Pearch</p>
				<p className="text-sm mb-4">Lee Min Ho</p>
			</div>

			<div>
				<label className="text-sm font-bold">Facilitator Group</label>
				<p className="text-sm mb-4">Facilitator Group 1</p>
			</div>

			<div>
				<label className="text-sm font-bold">Last Multiplication Date</label>
				<p className="text-sm mb-4">9 September 2019</p>
			</div>

			<div>
				<label className="text-sm font-bold">Head Facilitator</label>
				<p className="text-sm">Pevita Pearch</p>
				<p className="text-sm mb-4">Lee Min Ho</p>
			</div>

			<div>
				<label className="text-sm font-bold">Head Facilitator Group</label>
				<p className="text-sm">Pevita Pearch</p>
				<p className="text-sm mb-4">Lee Min Ho</p>
			</div>

			<div>
				<label className="text-sm font-bold">Last Multiplication Date</label>
				<p className="text-sm mb-4">9 September 2019</p>
			</div>
		</>
	)
}

const SGMemberProbationRequest = ({ openCard, handleTogle, handleViewMember }) => {
	return (
		<div>
			<hr className="w-full border-2 blue mx-4" />

			<div className="flex justify-between">
				<h4 className="font-bold">Members (1)</h4>
				<i
					id="members"
					className={`fa ${
						openCard.members ? 'fa-angle-up' : 'fa-angle-down'
					} text-3xl flex justify-center text-lg`}
					onClick={handleTogle}
				/>
			</div>
			{openCard.members && <Card dl={true} handleViewMember={() => handleViewMember(1)} />}

			<hr className="w-full border-2 blue mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">Probations (1)</h4>
					<i
						id="probations"
						className={`fa ${
							openCard.probations ? 'fa-angle-up' : 'fa-angle-down'
						} text-3xl flex justify-center`}
						onClick={handleTogle}
					/>
				</div>
				{openCard.probations && <Card ct={true} handleViewMember={handleViewMember} />}
			</div>

			<hr className="w-full border-2 blue mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">Request (1)</h4>
					<i
						id="requests"
						className={`fa ${
							openCard.requests ? 'fa-angle-up' : 'fa-angle-down'
						} text-3xl flex justify-center`}
						onClick={handleTogle}
					/>
				</div>
				{openCard.requests && <Card handleViewMember={handleViewMember} />}
			</div>

			<hr className="w-full border-2 blue mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">Probations (1)</h4>
					<i
						id="probations2"
						className={`fa ${
							openCard.probations2 ? 'fa-angle-up' : 'fa-angle-down'
						} text-3xl flex justify-center`}
						onClick={handleTogle}
					/>
				</div>
				{openCard.probations2 && <Card handleViewMember={handleViewMember} />}
			</div>
		</div>
	)
}

const Card = (props) => {
	return (
		<div>
			<div
				className="w-2/6 shadow-lg bg-white mb-4 mr-2 pb-2 relative cursor-pointer"
				onClick={props.handleViewMember}
			>
				{props.dl || props.ct ? (
					<RoleContainer value={props.leader}>
						<RoleRibbon value={props.leader}>
							{props.leader ? 'DL' : 'CT'}
						</RoleRibbon>
					</RoleContainer>
				) : null}
				<div className="pt-4 flex justify-center">
					<img
						src={photoplaceholder}
						className="rounded-full flex items-center justify-center w-32 h-32"
						alt="ministryMember"
					/>
				</div>
				<div className="font-bold text-center my-2">{props.fullname}</div>
				<hr className="border-2 border-blueSecondary mx-4" />
				<div className="flex text-center flex-col">
					<p>25 January 1992</p>
					<p>tonystark@gmail.com</p>
					<p>082112131616</p>
				</div>
			</div>
		</div>
	)
}
