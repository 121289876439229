import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'
import UserSearch from 'components/listUsers/UsersSearch'
import Loading from 'components/commons/Loading'
import UserItems from 'components/listUsers/UsersItems'
import { getUserList, getRoles } from 'libs/apis/usermanagement'
import { failedFetch } from 'libs/utils/messages'
import Pagination from 'components/commons/Pagination'

import {
	findUserById,
	toggleUser,
	updateData,
	findSelected,
	filterSelected,
	toggleActive,
	toggleDeactivate,
	updatecheckAll,
	findAllId,
	updateUnCheckAll,
	updateAllActive,
	updateAllDeactivate,
} from 'libs/utils/users'

import updateUserStatus from 'libs/apis/updateUserStatus'
import {
	somethingWrong,
	activeUserConfirmation,
	deactiveUserConfirmation,
} from 'libs/utils/confirmation'

export default function ListUsers(props) {
	const [data, setData] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [roles, setRoles] = useState([])
	const [filter, setFilter] = useState({
		name: '',
		phone: '',
		role: '',
		email: '',
		gender: '',
		status: '',
		page: 1,
	})
	const [orderBy, setOrderBy] = useState('')
	const [orderDir, setOrderDir] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	async function handleGetRoles() {
		let res = await getRoles()
		setRoles(res.data.data)
	}

	function handleOnChange(event) {
		setFilter({ ...filter, [event.target.name]: event.target.value, page: 1 })
	}

	function handleOnClear() {
		setFilter({ name: '', phone: '', role: '', email: '', gender: '', status: '', page: 1 })
	}

	function handlePageChange(page) {
		setCurrentPage(page)
	}

	async function handleOnFilter() {
		setIsLoadingData(true)

		try {
			const users = await getUserList(
				filter.page,
				filter.name || '',
				filter.email || '',
				filter.phone || '',
				filter.gender || '',
				filter.status || '',
				filter.role || '',
				orderBy,
				orderDir
			)
			if (users.data.api_status) {
				const data = users.data.data.data.map((item) => {
					item.checked = false
					return item
				})
				setIsLoadingData(false)

				setData(data)

				setCurrentPage(users.data.data.current_page)
				setLastPage(users.data.data.last_page)
			}
			setIsLoadingData(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage(failedFetch)
					setIsLoadingData(false)
				}
			}
		}
	}

	async function fetchData() {
		setIsLoadingData(true)

		try {
			const users = await getUserList(
				currentPage,
				filter.name || '',
				filter.email || '',
				filter.phone || '',
				filter.gender || '',
				filter.status || '',
				filter.role || '',
				orderBy,
				orderDir
			)
			if (users.data.api_status) {
				const data = users.data.data.data.map((item) => {
					item.checked = false
					return item
				})
				setIsLoadingData(false)

				setData(data)

				setCurrentPage(users.data.data.current_page)
				setLastPage(users.data.data.last_page)
			}
			setIsLoadingData(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage(failedFetch)
					setIsLoadingData(false)
				}
			}
		}
	}

	function handleOnChecked(id) {
		const user = findUserById(id, data)
		const toggledUser = toggleUser(user)
		const updatedData = updateData(data, toggledUser)

		const selectedUser = findSelected(id, selectedData)
		if (!selectedUser) {
			setSelectedData([...selectedData, id])
			setData(updatedData)
		} else {
			const filteredUser = filterSelected(id, selectedData)
			setData(updatedData)
			setSelectedData(filteredUser)
		}
	}

	async function handleActive(id) {
		const confirmed = await activeUserConfirmation()
		if (confirmed.value) {
			handleConfirmActive([id])
		}
	}

	async function handleConfirmActive(ids) {
		try {
			setIsLoadingData(true)

			const status = 1
			const res = await updateUserStatus(ids, status)

			if (res.data.api_status) {
				const user = findUserById(ids[0], data)
				const toggled = toggleActive(user)
				const updated = updateData(data, toggled)

				setData(updated)
				setIsLoadingData(false)
			} else {
				setIsLoadingData(false)
				const confirmedWrong = await somethingWrong()
				if (confirmedWrong.value) {
					handleConfirmActive(ids)
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					const confirm = await somethingWrong()
					if (confirm.value) {
						handleConfirmActive(ids)
					}
				}
			}
		}
	}

	async function handleCheckActive() {
		const confirmed = await activeUserConfirmation()
		if (confirmed.value && selectedData.length > 0) {
			handleConfirmAllActive(selectedData)
		}
	}

	async function handleConfirmAllActive(selectedData) {
		try {
			setIsLoadingData(true)

			const status = 1
			const res = await updateUserStatus(selectedData, status)

			if (res.data.api_status) {
				// const { data } = this.state
				const updated = updateAllActive(data, selectedData)

				setData(updated)
				setIsLoadingData(false)
			} else {
				setIsLoadingData(false)

				const confirmedWrong = await somethingWrong()
				if (confirmedWrong.value) {
					handleConfirmAllActive(selectedData)
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					const confirm = await somethingWrong()
					if (confirm.value) {
						handleConfirmAllActive(selectedData)
					}
				}
			}
		}
	}

	async function handleDeactivate(id) {
		const confirmed = await deactiveUserConfirmation()
		if (confirmed.value) {
			handleConfirmDeactivate([id])
		}
	}

	async function handleConfirmDeactivate(ids) {
		try {
			setIsLoadingData(true)

			const status = 0
			const res = await updateUserStatus(ids, status)

			if (res.data.api_status) {
				const { data } = this.state
				const user = findUserById(ids[0], data)
				const toggled = toggleDeactivate(user)
				const updated = updateData(data, toggled)
				setData(updated)
				setIsLoadingData(false)
			} else {
				this.setState({ isLoad: false })
				const confirmedWrong = await somethingWrong()
				if (confirmedWrong.value) {
					this.handleConfirmDeactivate(ids)
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.setState({ isRedirect: true })
				} else if (error.response.status >= 500) {
					const confirm = await somethingWrong()
					if (confirm.value) {
						handleConfirmDeactivate(ids)
					}
				}
			}
		}
	}

	async function handleCheckDeactive(params) {
		const confirmed = await deactiveUserConfirmation()
		if (confirmed.value) {
			handleConfirmAllDeactivate(selectedData)
		}
	}

	async function handleConfirmAllDeactivate(selectedData) {
		try {
			setIsLoadingData(true)
			const status = 0
			const res = await updateUserStatus(selectedData, status)

			if (res.data.api_status) {
				const updated = updateAllDeactivate(data, selectedData)
				setData(updated)
				setIsLoadingData(false)
			} else {
				setIsLoadingData(false)
				const confirmedWrong = await somethingWrong()
				if (confirmedWrong.value) {
					handleConfirmAllActive(selectedData)
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					const confirm = await somethingWrong()
					if (confirm.value) {
						handleConfirmAllActive(selectedData)
					}
				}
			}
		}
	}

	function handleCheckAll() {
		if (selectedData.length < data.length) {
			const updated = updatecheckAll(data)
			const selected = findAllId(data)
			setData(updated)
			setSelectedData(selected)
		} else if (selectedData.length === data.length) {
			const updated = updateUnCheckAll(data)
			setData(updated)
			setSelectedData([])
		}
	}

	function handleSort(sort) {
		if (orderBy !== sort) {
			setOrderBy(sort)
			setOrderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setOrderBy(sort)
				setOrderDir('desc')
			} else if (orderDir === 'desc') {
				setOrderBy(sort)
				setOrderDir('asc')
			}
		}
	}

	useEffect(() => {
		fetchData()
	}, [currentPage, filter.page, orderDir, orderBy])

	useEffect(() => {
		handleGetRoles()
	}, [])

	useEffect(() => {
		handleOnFilter()
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const churchName = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of All Users' }]

	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage && data.length > 0
	const displayNoData = !isLoadingData && !errorMessage && !data.length
	const displayError = !isLoadingData && errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{churchName} - List of All Users</title>
			</Helmet>
			<PageBreadcrumbs icon={UserIcon} title="List of All Users" breads={breads} />
			<div>
				<UserSearch
					roles={roles}
					filter={filter}
					handleOnFilter={handleOnFilter}
					handleOnChange={handleOnChange}
					handleOnClear={handleOnClear}
				/>
				{displayLoading && <Loading />}
				{displayData && (
					<div>
						<UserItems
							selectedData={selectedData}
							list={data}
							handleOnChecked={handleOnChecked}
							handleActive={handleActive}
							handleDeactivate={handleDeactivate}
							handleCheckAll={handleCheckAll}
							handleCheckActive={handleCheckActive}
							handleCheckDeactive={handleCheckDeactive}
							handleSort={handleSort}
						/>
						<Pagination
							currentPage={currentPage}
							lastPage={lastPage}
							onPageChange={handlePageChange}
						/>
					</div>
				)}
				{displayNoData && <div className="text-center">No data available</div>}
				{displayError && <div className="text-center">{errorMessage}</div>}
			</div>
		</div>
	)
}
