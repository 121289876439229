import * as React from 'react'
import SmallGroupTableRow from './SmallGroupTableRow'

function SmallGroupTable(props) {
	const { handleOnSort, handleOnClose, listSmallGroup } = props
	// const handleSortTime = partial(handleOnSort, 'time')
	const tableHead = [
		'CAMPUS',
		'SG NAME',
		'SG LEVEL',
		'SG CATEGORY',
		'SG LEADER(S)',
		'MEMBERS',
		'DAY',
		'TIME',
		'STATUS',
	]

	return (
		<div className="bg-white shadow-md rounded">
			<table className="w-full">
				<thead className="bt-blueSecondary py-4 border-2 border-solid">
					<tr>
						<th className="text-left" />
						{tableHead.map((header) => (
							<th className="text-left px-4 py-4">
								{header}
								<i className="fas fa-sort sortby cursor-pointer ml-1" />
							</th>
						))}
						<th className="px-2 py-4" />
					</tr>
				</thead>
				<tbody className="border-2 border-solid">
					{listSmallGroup.map((sg) => (
						<SmallGroupTableRow
							key={sg.campus}
							id={sg.id}
							campus={sg.campus}
							sgName={sg.sgName}
							sgLevel={sg.sgLevel}
							sgCategory={sg.sgCategory}
							sgLeaders={sg.sgLeaders}
							members={sg.members}
							day={sg.day}
							time={sg.time}
							status={sg.status}
							handleOnClose={handleOnClose}
						/>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default SmallGroupTable
