import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
// import moment from 'moment'
import 'react-quill/dist/quill.snow.css'

// import {
// 	cancelConfirmation,
// 	successMessage,
// 	failedMessage,
// 	somethingWrong,
// } from 'libs/utils/confirmation'
// import { updateSmallGroup } from 'libs/apis/smallGroup'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import SmallGroupFormik from 'components/formik/SmallGroupFormik'

function EditSmallGroup(props) {
	const [state, setState] = useState({
		campus: '',
		smallGroupLevel: '',
		parentCampus: '',
		parentSmallGroup: '',
		name: '',
		category: '',
		leader: '',
		smallGroupUpperLevelLeaderName: '',
		fullAddress: '',
		shortAddress: '',
		region: '',
		postalCode: '',
		day: '',
		time: '',
		status: '',
		established_date: '',
		ageFrom: '',
		ageTo: '',
		description: '',
	})
	const [categories, setCategories] = useState([])
	const [statuses, setStatuses] = useState([])
	const [regencies, setRegencies] = useState([])
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const displayLoading = isLoading && !errorMessage
	const displayErrorMessage = !isLoading && errorMessage
	const displayForm = !isLoading && !errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Small Group</title>
			</Helmet>
			<PageBreadcrumbs
				icon={SGIcon}
				title="Edit Group"
				breads={[
					{ url: '/small-group/list', title: 'List of All' },
					{ url: '', title: 'Edit Group' },
				]}
			/>
			{displayLoading && <Loading />}
			{displayErrorMessage && <div className="text-center">{errorMessage}</div>}
			{displayForm && (
				<SmallGroupFormik
					{...props}
					categories={categories}
					statuses={statuses}
					regencies={regencies}
					multi={true}
				/>
			)}
		</div>
	)
}

export default EditSmallGroup

// componentDidMount() {
// 	this.setState({ isLoading: true }, async () => {
// 		try {
// 			const id = this.props.match.params.id
// 			const response = await smallGroupDetail(id)
// 			const categories = await smallGroupCategories()
// 			const statuses = await smallGroupStatuses()
// 			const regencies = await smallGroupRegencies()

// 			if (
// 				response.data.api_status &&
// 				categories.data.api_status &&
// 				statuses.data.api_status &&
// 				regencies.data.api_status
// 			) {
// 				this.setState({
// 					isLoading: false,
// 					id: response.data.data.small_group_id,
// 					category: response.data.data.small_group_category_id,
// 					name: response.data.data.small_group_name,
// 					description: response.data.data.small_group_description,

// 					leader:
// 						response.data.data.leaders.length > 0
// 							? response.data.data.leaders[0].user_id
// 							: '-',
// 					day: response.data.data.time_day,
// 					time:
// 						(response.data.data.time_hours < 10
// 							? '0' + response.data.data.time_hours
// 							: response.data.data.time_hours) +
// 						':' +
// 						(response.data.data.time_minutes < 10
// 							? '0' + response.data.data.time_minutes
// 							: response.data.data.time_minutes),
// 					established_date: response.data.data.established_date,
// 					region: response.data.data.regency_id,
// 					status: response.data.data.small_group_status,
// 					fullAddress: response.data.data.long_address,
// 					shortAddress: response.data.data.short_address,
// 					postalCode: response.data.data.postal_code,
// 					leaders:
// 						response.data.data.leaders.length > 0
// 							? response.data.data.leaders
// 							: [],
// 					members: response.data.data.members,
// 					categories: categories.data.data,
// 					statuses: statuses.data.data,
// 					regencies: regencies.data.data,
// 				})
// 			} else {
// 				this.setState({ isLoading: false, errorMessage: response.data.message })
// 			}
// 		} catch (error) {
// 			if (error.response) {
// 				if (error.response.status === 401) {
// 					await localStorage.removeItem('token')
// 					this.setState({ isRedirect: true })
// 				} else if (error.response.status >= 500) {
// 					this.setState({
// 						isLoading: false,
// 						errorMessage:
// 							'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
// 					})
// 				}
// 			}
// 		}
// 	})
// }
