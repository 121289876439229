import React from 'react'
import styled from 'styled-components'

function ButtonExport({ style, children }) {
	return (
		<div className={`inline-block ${style}`}>
			<select class="form-select bg-white border-white border-solid border-2 text-primary font-bold block w-full shadow-md rounded-lg focus:outline-none">
				<option>Export</option>
				<option>Small Groups</option>
				<option>Members</option>
			</select>
		</div>
	)
}

export default ButtonExport
