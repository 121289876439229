import * as React from 'react'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import { smallGroupSearchUser } from 'libs/apis/smallGroup'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { TextInput } from 'components/commons/TextInput'
import { constants } from 'constants/constant'
import { Button } from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import SelectOptions from 'components/commons/SelectOptions'
import { InputTime } from 'components/commons/InputTime'
import { InputDate } from 'components/commons/InputDate'
import TextArea from 'components/commons/TextArea'
import { DescriptionBox } from 'components/commons/DescriptionBox'

function SmallGroupForm(props) {
	const {
		smallGroupLevel,
		parentSmallGroup,
		smallGroupUpperLevelLeaderName,
		category,
		description,
		name,
		status,
		leader,
		day,
		time,
		established_date,
		fullAddress,
		shortAddress,
		region,
		postalCode,
		ageFromTo,
	} = props.values

	const { setFieldValue, isSubmitting } = props
	const errors = props.errors

	const handleOnLoadLeaders = async (value) => {
		const response = await smallGroupSearchUser(value, '')

		if (response.data.api_status) {
			const options = response.data.data.map((user) => ({
				value: user.user_id,
				label: user.fullname + ' - ' + user.email,
			}))
			return options
		}
	}

	const handleOnLeaderChange = (input) => {}

	const handleOnChange = (event) => {
		setFieldValue(event.target.name, event.target.value)
	}

	const handleOnTime = (date) => {
		setFieldValue('time', moment(date[0]).format('HH:mm'))
	}

	const handleOnDescription = (value) => {
		setFieldValue('description', value)
	}

	const handleEstabilishDate = (date) => {
		setFieldValue('established_date', moment(date[0]).format('DD/MM/YYYY'))
	}

	const handleOnSubmit = () => {
		props.handleSubmit()
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			props.history.push('/small-group/list')
		}
	}

	const { categories, statuses, regencies } = props
	const label = props.multi ? 'SMALL GROUP CAMPUS' : 'CAMPUS'

	return (
		<div className="bg-white shadow-md p-4">
			<div className="w-1/2 mb-6">
				<SelectOptions
					name={'smallGroupCampus'}
					label={label}
					options={[]}
					value
					onChange={handleOnChange}
					size={'large'}
					errors={errors.campus}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions
					name={'smallGroupLevel'}
					label={'SMALL GROUP LEVEL'}
					options={[]}
					value={smallGroupLevel}
					onChange={handleOnChange}
					size={'large'}
					errors={errors.smallGroupLevel}
				/>
			</div>

			<div className="flex">
				<div className="w-1/2 mb-6">
					<SelectOptions
						name={'parentCampus'}
						label={'PARENT CAMPUS'}
						options={[]}
						value={parentSmallGroup}
						onChange={handleOnChange}
						size={'large'}
						errors={errors.category}
					/>
				</div>
				{props.multi && (
					<div className="w-1/2 mb-6 ml-2">
						<SelectOptions
							name={'parentSmallGroup'}
							label={'PARENT SMALL GROUP'}
							options={[]}
							value={parentSmallGroup}
							onChange={handleOnChange}
							size={'large'}
							errors={errors.category}
						/>
					</div>
				)}
			</div>

			<div className="w-1/2 mb-6">
				<TextInput
					name={'name'}
					label={'SMALL GROUP NAME'}
					value={name}
					onChange={handleOnChange}
					errors={errors.name}
					active={name}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions
					name={'category'}
					label={'SMALL GROUP CATEGORY'}
					options={categories}
					value={category}
					onChange={handleOnChange}
					size={'large'}
					errors={errors.category}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<TextInput
					name={'smallGroupLeader'}
					label={'SMALL GROUP LEADER'}
					value={leader}
					onChange={handleOnChange}
					errors={errors.name}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<TextInput
					name={'smallGroupUpperLevelLeaderName'}
					label={'UPPER LEVEL SGL NAME'}
					value={smallGroupUpperLevelLeaderName}
					onChange={handleOnChange}
					errors={errors.smallGroupUpperLevelLeaderName}
				/>
			</div>

			<div className="flex">
				<div className="w-1/2 h-full">
					<TextArea
						label={'FULL ADDRESS'}
						name={'fullAddress'}
						value={fullAddress}
						handleOnChange={handleOnChange}
						errors={errors.fullAddress}
					/>
				</div>

				<div className="w-1/2">
					<div className="mb-6 ml-2">
						<TextInput
							name={'shortAddress'}
							label={'SHORT ADDRESS'}
							value={shortAddress}
							onChange={handleOnChange}
							errors={errors.shortAddress}
						/>
					</div>
					<div className="flex">
						<div className="w-full ml-2 mr-2">
							<SelectOptions
								name={'region'}
								label={'REGION'}
								options={regencies}
								value={region}
								onChange={handleOnChange}
								size={'large'}
								errors={errors.region}
							/>
						</div>

						<div className="w-full">
							<TextInput
								name={'postalCode'}
								label={'POSTAL CODE'}
								value={postalCode}
								onChange={handleOnChange}
								size={'small'}
								errors={errors.postalCode}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="flex">
				<div className="w-1/2 mb-6">
					<SelectOptions
						name={'day'}
						label={'SCHEDULE: DAY'}
						options={constants.days}
						value={day}
						onChange={handleOnChange}
						errors={errors.day}
					/>
				</div>
				<div className="w-1/2 mb-6 ml-2">
					<InputTime
						name={'time'}
						label={'SCHEDULE: TIME'}
						value={time}
						handleChange={handleOnTime}
						errors={errors.time}
					/>
				</div>
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions
					name={'status'}
					label={'SMALL GROUP STATUS'}
					options={statuses}
					value={status}
					onChange={handleOnChange}
					errors={errors.day}
				/>
			</div>

			<div className="mb-6 w-3/6">
				<InputDate
					name="established_date"
					label={'ESTABLISHED DATE'}
					value={established_date}
					handleChange={handleEstabilishDate}
					errors={errors.established_date}
				/>
			</div>

			<div className="flex w-1/2 mb-6">
				<div className="w-full">
					<TextInput
						name={'ageFromTo'}
						label={'AGE FROM'}
						value={ageFromTo}
						onChange={handleOnChange}
						size={'small'}
						errors={errors.ageFromTo}
					/>
				</div>

				<div className="w-full ml-2">
					<TextInput
						name={'ageFromTo'}
						label={'TO'}
						value={ageFromTo}
						onChange={handleOnChange}
						size={'small'}
						errors={errors.ageFromTo}
					/>
				</div>
			</div>

			<div className="w-1/2 mb-6">
				<DescriptionBox
					label={'SMALL GROUP DESCRIPTION'}
					value={description}
					handleChange={handleOnDescription}
					errors={errors.description}
				/>
			</div>

			<div className="flex justify-between">
				<Button type="cancel" handleClick={handleCancel}>
					{isSubmitting ? <Loading secondary /> : 'Cancel'}
				</Button>
				<Button type="primary" handleClick={handleOnSubmit}>
					{isSubmitting ? <Loading secondary /> : 'Save'}
				</Button>
			</div>
		</div>
	)
}

export default SmallGroupForm
