import React from 'react'

export function TextInput(props, { label, size, handleChange, value, isActive, name, errors = props.errors }) {
	const small = `${size === 'small' ? 'w-64' : ''}`
	const medium = `${size === 'medium' ? 'w-3/6' : ''}`
	const large = `${size === 'large' ? 'w-full' : ''}`
	const active = `${isActive === true ? 'bg-primary' : 'bg-white'}`

	return (
		<div>
			<div className="flex flex-col">
				<label className="block text-gray-700 text-sm font-bold mb-2">{label || props.label}</label>
				<input
					type="text"
					name={name || props.name}
					className={`${small || medium || large} ${active} py-2 pl-3 border-secondary border-2 rounded-lg`}
					value={value || props.value}
					onChange={handleChange || props.onChange}
				/>
				{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
			</div>
		</div>
	)
}
