import React from 'react'

const TextArea = ({ name, value, onChange, error, field, size, label }) => {
	const defaultStyle = `appearance-none border-2 rounded-lg h-40 p-3 leading-tight focus:outline-none border-secondary`

	let sizearea = {
		large: 'w-full',
		medium: 'w-1/2',
	}

	const style = [defaultStyle, sizearea[size]].join(' ')

	return (
		<div>
			{label && (
				<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			)}
			<textarea
				className={style}
				name={name || field.name}
				value={value || field.value}
				onChange={onChange || field.onChange}
			/>
			{error && <div className="text-red-500 text-xs italic">{error}</div>}
		</div>
	)
}

export default TextArea
