import { withFormik } from 'formik'
import validationSchema from './NewsValidSchema'
import NewsfeedForm from '../forms/NewsfeedForm'
import { createNews, updateNews } from 'libs/apis/newsfeeds'
import {
	saveDraftConfirmation,
	successMessage,
	failedMessage,
	somethingWrong,
} from 'libs/utils/confirmation'

const NewsfeedFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		id: props.id || '',
		media: props.media || 'Image',
		image: props.image || '',
		video: props.video || '',
		title: props.title || '',
		description: props.description || '',
		hashtags: props.hashtags || [],
		hashtag: '',
		startDate: props.startDate || '',
		endDate: props.endDate || '',
		endDateCheck: props.endDate ? true : false,
		isDraft: false,
		isCreateForm: props.isCreateForm || false,
	}),
	validationSchema: validationSchema,
	async handleSubmit(values, { props, setSubmitting, setFieldValue }) {
		const {
			media,
			image,
			video,
			title,
			description,
			hashtags,
			startDate,
			endDateCheck,
			endDate,
			isDraft,
			isCreateForm,
			id,
		} = values
		let formdata = new FormData()
		formdata.append('media_type', media)
		media === 'Image'
			? formdata.append('media_url', image)
			: formdata.append('media_url', video)
		formdata.append('title', title)
		formdata.append('description', description)
		if (hashtags.length > 0) {
			for (let index = 0; index < hashtags.length; index++) {
				formdata.append('hashtags[]', hashtags[index])
			}
		}
		formdata.append('start_post', startDate)
		if (endDateCheck) {
			formdata.append('end_post', endDate)
		}
		isDraft ? formdata.append('is_draft', 1) : formdata.append('is_draft', 0)

		try {
			if (isCreateForm) {
				if (isDraft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await createNews(formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/newsfeed/list')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('isDraft', false)
				} else if (!isDraft) {
					const response = await createNews(formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/newsfeed/list')
					} else {
						failedMessage(response.data.message)
					}
				}
			} else if (!isCreateForm) {
				if (isDraft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await updateNews(id, formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/newsfeed/list')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('isDraft', false)
				} else if (!isDraft) {
					const response = await updateNews(id, formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/newsfeed/list')
					} else {
						failedMessage(response.data.message)
					}
				}
			}
			setSubmitting(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					props.history.push('/login')
				} else {
					setSubmitting(false)

					await somethingWrong()
				}
			}
		}
	},
})(NewsfeedForm)

export default NewsfeedFormik
