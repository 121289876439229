import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import AppointmentsTable from 'components/smallGroup/AppointmentsTable'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Searchbar from 'components/commons/Searchbar'
import AppointmentsFilter from 'components/smallGroup/AppointmentsFilter'
import PaginationPage from 'components/commons/PaginationPage'
import { SmallGroupHeader } from './styles'
import ButtonExport from 'components/commons/ButtonExport'

function ListAppointments() {
	const [listAppointments, setListAppointments] = useState([
		{
			id: 1,
			type: 'Type A',
			title: 'Appointment Title',
			date: '3 Januari 2020',
			time: '19:00',
			createdBy: 'Taylor Swift',
			attendee: [12, 15],
		},
		{
			id: 2,
			type: 'Type B',
			title: 'Appointment Title',
			date: '3 Januari 2020',
			time: '19:00',
			createdBy: 'Selena Gomez',
			attendee: [12, 15],
		},
		{
			id: 3,
			type: 'Type C',
			title: 'Appointment Title',
			date: '3 Januari 2020',
			time: '19:00',
			createdBy: 'Camila Cabello',
			attendee: [12, 15],
		},
		{
			id: 4,
			type: 'Type D',
			title: 'Appointment Title',
			date: '3 Januari 2020',
			time: '19:00',
			createdBy: 'Velove Vexia',
			attendee: [12, 15],
		},
	])
	const [filter, setFilter] = useState({
		types: [],
	})

	const handleSelectFilter = (event) => {
		if (filter.types.includes(event.target.value)) {
			let selectFilter = filter.types.filter((val) => val !== event.target.value)
			setFilter({ ...filter, types: selectFilter })
		} else {
			setFilter({ ...filter, types: [...filter.types, event.target.value] })
		}
	}

	const handleClearFilter = () => {
		setFilter({ ...filter, types: [] })
	}

	const handleApplyFilter = () => {
		console.log(filter, 'filter')
	}

	const breads = [{ url: '', title: 'Appointments' }]
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List of Appointments </title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="List of Appointments" breads={breads} />
			<SmallGroupHeader>
				<AppointmentsFilter
					handleSelectFilter={handleSelectFilter}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					filter={filter}
				/>
				<ButtonExport style="ml-16">Export</ButtonExport>

				<Searchbar />
			</SmallGroupHeader>
			<div>
				<AppointmentsTable list={listAppointments} />
				<PaginationPage />
			</div>
		</div>
	)
}

export default ListAppointments
