import React from 'react'
import styled from 'styled-components'

const SearchContainer = styled.div`
	width: 100%;

	@media (min-width: 768px) {
		width: 33.33%;
	}
`

const Searchbar = ({ filterKeyword, handleOnSearch }) => {
	return (
		<SearchContainer>
			<div className="shadow-md flex rounded-lg bg-white px-6 py-2 mx-2 mb-4">
				<i
					className="fas fa-search"
					style={{ color: '#4da5dc', marginTop: '3px', marginRight: '10px' }}
				/>
				<input
					type="text"
					className="w-full"
					placeholder="Search"
					name="filterKeyword"
					value={filterKeyword}
					onChange={handleOnSearch}
				/>
			</div>
		</SearchContainer>
	)
}

export default Searchbar
