import React from 'react'

export default function TextArea({ name, label, value, handleOnChange, errors }) {
	return (
		<div className="mb-6">
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<textarea
				className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary"
				name={name}
				value={value}
				onChange={handleOnChange}
				rows={6}
			/>
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}
