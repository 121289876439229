import React from 'react'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import moment from 'moment'
import TableBody from '../commons/table/TableBody'
import ActionsRow from '../commons/table/ActionsRow'
import TableHeader from '../commons/table/TableHeader'

const DevotionTable = (props) => {
	const columns = [
		{
			key: 'status',
			content: (devotion) => {
				if (devotion.is_highlight)
					return (
						<td className="relative mr-6">
							<Tippy content="highlight" theme="star">
								<i className="fas fa-star text-yellow-300 self-center flex" />
							</Tippy>
						</td>
					)

				if (devotion.status === 'Published') return <td></td>

				return (
					<td
						className={`absolute mr-6 ${
							devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<Tippy content={devotion.status}>
							<div className="status-post-tag">
								<div className="ribbon-black-triagle" />
							</div>
						</Tippy>
					</td>
				)
			},
		},
		{
			label: 'ID',
			path: 'devotion_id',
			key: 'devotion_id',
			content: (devotion) => (
				<td className="px-2 py-4 text-center">{devotion.devotion_id}</td>
			),
		},
		{
			label: 'TITLE',
			path: 'title',
			content: (devotion) => (
				<td
					className={`px-2 py-4 w-5 truncate max-w-xs ${
						devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
					}`}
				>
					<Link to={`/devotion/edit/${devotion.devotion_id}`}>{devotion.title}</Link>
				</td>
			),
		},
		{
			label: 'START SHARING',
			path: 'start_post',
			content: (devotion) => {
				const start_post = moment(devotion.start_post, 'DD-MM-YYYY HH:mm')
				return (
					<td
						className={`px-2 py-4 ${
							devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<div className="text-center">
							<div>{start_post.format('D MMM YYYY')}</div>
							<div>{start_post.format('HH:mm')}</div>
						</div>
					</td>
				)
			},
		},
		{
			label: 'END SHARING',
			path: 'end_post',
			content: (devotion) => {
				if (devotion.end_post) {
					const end_post = moment(devotion.end_post, 'DD/MM/YYYY HH:mm')
					return (
						<td
							className={`px-2 py-4 ${
								devotion.status === 'Archived'
									? 'opacity-25'
									: 'opacity-100'
							}`}
						>
							<div className="text-center">
								<div>{end_post.format('D MMM YYYY')}</div>
								<div>{end_post.format('HH:mm')}</div>
							</div>
						</td>
					)
				}
				return (
					<td
						className={`px-2 py-4 text-center ${
							devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						-
					</td>
				)
			},
		},
		{
			label: 'CREATED ON',
			path: 'created_at',
			content: (devotion) => (
				<td
					className={`px-2 py-4 text-center ${
						devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
					}`}
				>
					<div>{moment(devotion.created_at).format('D MMM YYYY')}</div>
					<div className="text-primary">{devotion.created_by_name}</div>
				</td>
			),
		},
		{
			label: 'LAST UPDATED',
			path: 'updated_at',
			content: (devotion) => {
				const updated_at = moment(devotion.updated_at)

				return (
					<td
						className={`px-2 py-4 text-center ${
							devotion.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<div>{updated_at.format('D MMM YYYY')}</div>
						<div className="text-primary">{updated_at.fromNow()}</div>
					</td>
				)
			},
		},
		{
			key: 'action',
			content: (devotion) => {
				const classes = 'px-4 py-2 block hover:bg-blueSecondary'
				const actions = [
					{
						key: 'viewEdit',
						helper: (
							<li className={classes}>
								<Link to={`/devotion/edit/${devotion.devotion_id}`}>
									View & Edit
								</Link>
							</li>
						),
					},
					{
						key: 'archived',
						path: 'Archived',
						helper: (
							<li
								className={classes}
								onClick={() => props.onArchive(devotion)}
							>
								Archive
							</li>
						),
					},
					{
						key: 'delete',
						helper: (
							<li className={classes} onClick={() => props.onDelete(devotion)}>
								Delete
							</li>
						),
					},
				]

				const filtered = actions.filter((action) => action.path !== devotion.status)
				return <ActionsRow actions={filtered} />
			},
		},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0">
			<table className="min-w-full">
				<TableHeader
					columns={columns}
					sortColumn={props.sortColumn}
					onSort={props.onSort}
				/>
				<TableBody data={props.devotions} columns={columns} />
			</table>
		</div>
	)
}

export default DevotionTable
