import { withFormik } from 'formik'
import validationSchema from './CampaignEmailValidSchema'
import { createEmailCampaign, updateEmailCampaign } from 'libs/apis/emailCampaign'
import {
	saveDraftConfirmation,
	successMessage,
	failedMessage,
	somethingWrong,
} from 'libs/utils/confirmation'
import CampaignEmailForm from '../forms/CampaignEmailForm'

const CampaignEmailFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		id: props.id || '',
		subject: props.subject || '',
		content: props.content || '',
		image_url: props.image_url || '',
		status: props.status || '',
		media: props.media || 'Image',
		attachment: props.attachment || '',
		filter_gender: props.filter_gender || 'ALL',
		filter_marital_status: props.filter_marital_status || 'ALL',
		filter_roles: props.filter_roles || [],
		filter_role: '',
		schedule: props.schedule || '',
		is_draft: 0,
		isCreatedForm: props.isCreatedForm || false,
		visibleToAll: false,
	}),
	validationSchema,
	handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
		const {
			id,
			subject,
			content,
			attachment,
			schedule,
			isCreatedForm,
			is_draft,
			filter_roles,
			filter_gender,
			filter_marital_status,
			image_url,
			status,
		} = values

		let formdata = new FormData()
		formdata.append('subject', subject)
		formdata.append('content', content)
		formdata.append('image_url', image_url)
		formdata.append('attachment', attachment)
		formdata.append('schedule', schedule)
		formdata.append('filter_marital_status[]', filter_marital_status)
		formdata.append('filter_gender[]', filter_gender)

		if (filter_roles.length > 0) {
			for (let i = 0; i < filter_roles.length; i++) {
				formdata.append('filter_role[]', filter_roles[i])
			}
		}

		if (is_draft) {
			formdata.append('is_draft', 1)
		} else {
			formdata.append('status', status)
		}

		try {
			if (isCreatedForm) {
				if (is_draft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await createEmailCampaign(formdata)

						if (response.data.api_status) {
							await successMessage()
							props.history.push('/campaign')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('is_draft', 0)
				} else if (!is_draft) {
					const response = await createEmailCampaign(formdata)

					if (response.data.api_status) {
						await successMessage()
						props.history.push('/campaign')
					} else {
						failedMessage(response.data.message)
					}
				}
			} else if (!isCreatedForm) {
				if (is_draft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await updateEmailCampaign(id, formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/campaign')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('is_draft', 0)
				} else if (!is_draft) {
					const response = await updateEmailCampaign(id, formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/campaign')
					} else {
						failedMessage(response.data.message)
					}
				}
			}
			setSubmitting(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					props.history.push('/login')
				} else {
					setSubmitting(false)
					somethingWrong()
				}
			}
		}
	},
})(CampaignEmailForm)

export default CampaignEmailFormik
