import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import Loading from 'components/commons/Loading'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { getUserDetail } from 'libs/apis/usermanagement'

class ViewUser extends Component {
	state = {
		isLoadingData: false,
		errorMessage: '',
		userProfile: '',
		fullname: '',
		email: '',
		phone: '',
		genderDesc: '',
		dob: '',
		memberSince: '',
		birthplace: '',
		address: '',
		city: '',
		homeNumber: '',
		baptismChurch: '',
		baptismCity: '',
		baptismDate: '',
		lastEdu: '',
		fieldEdu: '',
		occupation: '',
		fieldJob: '',
		company: '',
		maritalStatus: '',
		spouse: {},
		childs: [],
		small_group_name: '',
		completed_class: [],
		ministryList: [],
	}

	componentDidMount() {
		this.setState({ isLoadingData: true }, async () => {
			try {
				const response = await getUserDetail(this.props.match.params.id)
				if (response.data.api_status) {
					this.setState({
						isLoadingData: false,
						profilePhoto: response.data.data.profile_photo,
						fullname: response.data.data.fullname,
						email: response.data.data.email,
						phone: response.data.data.phone_number,
						genderDesc: response.data.data.gender_desc,
						dob: response.data.data.dob,
						memberSince: response.data.data.created_at,
						birthplace: response.data.data.birthplace,
						address: response.data.data.address,
						city: response.data.data.city,
						homeNumber: response.data.data.home_number,
						baptismChurch: response.data.data.baptism_church,
						baptismCity: response.data.data.baptism_city,
						baptismDate: response.data.data.baptism_date,
						lastEdu: response.data.data.last_education,
						fieldEdu: response.data.data.field_of_education,
						occupation: response.data.data.occupation,
						fieldJob: response.data.data.field_of_job,
						company: response.data.data.company,
						maritalStatus: response.data.data.marital_status,
						spouse: response.data.data.spouse,
						childs: response.data.data.childs,
						small_group_name: response.data.data.small_group_name,
						completed_class: response.data.data.completed_class,
						ministryList: response.data.data.ministry,
					})
				}
			} catch (error) {}
		})
	}

	regexChecker(string) {
		var ua = navigator.userAgent.toLowerCase()
		if (ua.indexOf('safari') !== -1) {
			if (ua.indexOf('chrome') > -1) {
				let emailRegex = string.replace(new RegExp('(?<=.)[^@](?=[^@]*?@)|(?:(?<=@.)|(?!^)\\G(?=[^@]*$)).(?!$)', 'gm'), '*')
				return emailRegex
			} else {
				return string
			}
		}
	}

	renderData = () => {
		const {
			profilePhoto,
			fullname,
			email,
			phone,
			genderDesc,
			dob,
			memberSince,
			birthplace,
			address,
			city,
			homeNumber,
			baptismChurch,
			baptismCity,
			baptismDate,
			lastEdu,
			fieldEdu,
			occupation,
			fieldJob,
			company,
			maritalStatus,
			spouse,
			childs,
			completed_class,
			ministryList,
		} = this.state

		let ministryName = ministryList.map((ministry) => ministry.ministry_name)

		let ministryRole = ministryList.map((ministry) => {
			if (ministry.show_as === '') {
				return ministry.role
			}
			return ministry.show_as
		})

		let regexEmail = this.regexChecker(email)

		let json = JSON.parse(localStorage.getItem('church'))
		let churchCode = json.church_code

		return (
			<div>
				<div className="bg-white mb-6 -mx-6">
					<div className="flex">
						<div className="w-2/12">
							<div className="flex justify-center items-center flex-col h-full">
								{profilePhoto ? (
									<img className="rounded-full h-24 w-24 flex items-center justify-center" src={profilePhoto} alt="profile" />
								) : (
									<img className="rounded-full h-24 w-24 flex items-center justify-center" src={photoplaceholder} alt="profile" />
								)}
							</div>
						</div>
						<div className="w-5/12 my-auto">
							<div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Full Name</div>
										<div className="flex-1 font-bold">{fullname}</div>
									</div>
								</div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Email</div>
										<div className="flex-1 font-bold">{churchCode === 'skybr.id' ? regexEmail : email}</div>
									</div>
								</div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Phone Number</div>
										<div className="flex-1 font-bold">{churchCode === 'skybr.id' ? '+62********' + phone.slice(11, 14) : phone}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="w-5/12 my-auto">
							<div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Gender</div>
										<div className="flex-1 font-bold">{genderDesc}</div>
									</div>
								</div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Birthday</div>
										<div className="flex-1 font-bold">{dob ? moment(dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</div>
									</div>
								</div>
								<div className="p-2">
									<div className="flex">
										<div className="flex-1 text-grayBold font-bold">Member Since</div>
										<div className="flex-1 font-bold">{moment(memberSince, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2 mr-4">
						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex flex-col">
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Birthplace</div>
									<div className="w-1/2 font-bold">{birthplace ? birthplace : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Address</div>
									<div className="w-1/2 font-bold">{address ? address : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">City</div>
									<div className="w-1/2 font-bold">{city ? city : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Home Number</div>
									<div className="w-1/2 font-bold">{homeNumber ? homeNumber : '-'}</div>
								</div>
							</div>
						</div>
						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex flex-col">
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Baptism Church</div>
									<div className="w-1/2 font-bold">{baptismChurch ? baptismChurch : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Baptism City</div>
									<div className="w-1/2 font-bold">{baptismCity ? baptismCity : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Baptism Date</div>
									<div className="w-1/2 font-bold">{baptismDate ? moment(baptismDate, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</div>
								</div>
							</div>
						</div>
						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex flex-col">
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Last Education</div>
									<div className="w-1/2 font-bold">{lastEdu ? lastEdu : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Field of Education</div>
									<div className="w-1/2 font-bold">{fieldEdu ? fieldEdu : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Occupation</div>
									<div className="w-1/2 font-bold">{occupation ? occupation : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Field of Job</div>
									<div className="w-1/2 font-bold">{fieldJob ? fieldJob : '-'}</div>
								</div>
								<div className="flex mb-4">
									<div className="w-1/2 text-grayBold font-bold">Company Name</div>
									<div className="w-1/2 font-bold">{company ? company : '-'}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-1/2">
						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex mb-4">
								<div className="w-1/2 text-grayBold font-bold">Small Group</div>
								<div className="w-1/2 font-bold">{this.state.small_group_name ? this.state.small_group_name : '-'}</div>
							</div>
						</div>
						{completed_class.length > 0 && (
							<div className="bg-white mb-4 p-4 shadow-md">
								<div className="w-full text-grayBold font-bold">Classes:</div>
								{completed_class.map((item, index) => {
									return (
										<div className="flex mb-4" key={index}>
											<div className="w-1/2 font-bold">{item.short_title}</div>
											<div className="w-1/2 font-bold">{moment(item.completed_at, 'YYYY-MM-DD').format('DD MMMM YYYY')}</div>
										</div>
									)
								})}
							</div>
						)}
						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex mb-4">
								<div className="w-1/2 text-grayBold font-bold">Marital Status</div>
								<div className="w-1/2 font-bold">{maritalStatus ? maritalStatus : ''}</div>
							</div>
							<div className="flex mb-4">
								<div className="w-1/2 text-grayBold font-bold">Spouse</div>
								<div className="w-1/2 font-bold">{spouse ? spouse.fullname : '-'}</div>
							</div>
							<div className="flex mb-4">
								<div className="w-1/2 text-grayBold font-bold">Spouse Birthday</div>
								<div className="w-1/2 font-bold">{spouse ? (spouse.dob ? moment(spouse.dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-') : '-'}</div>
							</div>
							<div className="flex mb-4">
								<div className="w-1/2 text-grayBold font-bold">Wedding Date</div>
								<div className="w-1/2 font-bold">{spouse ? (spouse.weeding_date ? moment(spouse.weeding_date, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-') : '-'}</div>
							</div>
							{childs.length > 0 && (
								<div>
									<div className="flex mb-4">
										<div className="w-1/2 text-grayBold font-bold">1st Child</div>
										<div className="w-1/2 font-bold">{childs.length > 0 ? childs[0].fullname : '-'}</div>
									</div>
									<div className="flex mb-4">
										<div className="w-1/2 text-grayBold font-bold">Gender</div>
										<div className="w-1/2 font-bold">{childs.length > 0 ? (childs[0].gender_desc ? childs[0].gender_desc : '-') : '-'}</div>
									</div>
									<div className="flex mb-4">
										<div className="w-1/2 text-grayBold font-bold">Child Birthday</div>
										<div className="w-1/2 font-bold">{childs.length > 0 ? (childs[0].dob ? moment(childs[0].dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-') : '-'}</div>
									</div>
									{childs.length > 1 && (
										<div>
											<div className="flex mb-4">
												<div className="w-1/2 text-grayBold font-bold">2nd Child</div>
												<div className="w-1/2 font-bold">{childs.length > 1 ? childs[1].fullname : '-'}</div>
											</div>
											<div className="flex mb-4">
												<div className="w-1/2 text-grayBold font-bold">Gender</div>
												<div className="w-1/2 font-bold">{childs.length > 1 ? (childs[1].gender_desc ? childs[1].gender_desc : '-') : '-'}</div>
											</div>
											<div className="flex mb-4">
												<div className="w-1/2 text-grayBold font-bold">Child Birthday</div>
												<div className="w-1/2 font-bold">
													{childs.length > 1 ? (childs[1].dob ? moment(childs[1].dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-') : '-'}
												</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="bg-white mb-4 p-4 shadow-md">
							<div className="flex justify-between mb-4">
								<div>
									<div className="text-grayBold font-bold">Ministry Name</div>
									<div className="flex flex-col">
										{ministryName.map((name, index) => (
											<div key={index} className="font-bold capitalize">
												{name}
											</div>
										))}
									</div>
								</div>
								<div>
									<div className="text-grayBold font-bold">Ministry Role</div>
									<div className="flex flex-col mr-16">
										{ministryRole.map((role, index) => (
											<div key={index} className="font-bold capitalize">
												{role}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { isLoadingData, errorMessage } = this.state
		const displayLoading = isLoadingData && !errorMessage
		const displayData = !isLoadingData && !errorMessage
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [
			{ url: '/user-management/list', title: 'List of All Users' },
			{ url: '', title: 'View User' },
		]

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - View User</title>
				</Helmet>
				<PageBreadcrumbs icon={UserIcon} title="View User" breads={breads} />
				{displayLoading && <Loading />}
				{displayData && this.renderData()}
			</div>
		)
	}
}

export default ViewUser
