import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { useParams } from 'react-router-dom'
import { getUserDetail } from 'libs/apis/usermanagement'
// import { getUserDetail } from 'libs/apis/usermanagement'
// import Loading from 'components/commons/Loading'

function SmallGroupViewUser(props) {
	const [state, setState] = useState({
		userProfile: '',
		fullname: '',
		email: '',
		phone: '',
		genderDesc: '',
		dob: '',
		memberSince: '',
		birthplace: '',
		address: '',
		city: '',
		homeNumber: '',
		baptismChurch: '',
		baptismCity: '',
		baptismDate: '',
		lastEdu: '',
		fieldEdu: '',
		occupation: '',
		fieldJob: '',
		company: '',
		maritalStatus: '',
		spouse: [],
		childs: [],
		small_group_name: '',
		completed_class: [],
	})
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const { sgId, id } = useParams()

	useEffect(() => {
		const fetchMemberDetails = async () => {
			try {
				let response = await getUserDetail(id)
				if (response.data.api_status) {
					// setState({
					// 	...state,
					// 	userProfile,
					// 	fullname,
					// 	email,
					// 	phone,
					// 	genderDesc,
					// 	dob,
					// 	memberSince,
					// 	birthplace,
					// 	address,
					// 	city,
					// 	homeNumber,
					// 	baptismChurch,
					// 	baptismCity,
					// 	baptismDate,
					// 	lastEdu,
					// 	fieldEdu,
					// 	occupation,
					// 	fieldJob,
					// 	company,
					// 	maritalStatus,
					// 	spouse,
					// 	childs,
					// 	small_group_name,
					// 	completed_class
					// })
				}
			} catch (error) {
				console.log(error)
				setIsLoadingData(false)
				setErrorMessage(
					'There something wrong happend, Cannot retrieve user data for the moment, Please contact us'
				)
			}
		}

		fetchMemberDetails()
	}, [id])

	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/small-group/list', title: 'List of All Groups' },
		{
			url: `/small-group/view/${props.match.params.sgId}`,
			title: 'View Group',
		},
		{ url: '', title: 'View Member' },
	]

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View User</title>
			</Helmet>
			<PageBreadcrumbs icon={UserIcon} title="View User" breads={breads} />
			{/* {displayLoading && <Loading />} */}
			{displayData && (
				<div>
					<MemberDetails1 info={state} />
					<div className="flex">
						<div className="w-1/2 mr-4">
							<MemberDetails2 info={state} />
							<MemberDetails3 info={state} />
							<MemberDetails4 info={state} />
						</div>
						<div className="w-1/2">
							<MemberDetails5 info={state} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SmallGroupViewUser

function MemberDetails1(props) {
	const { profilePhoto, fullname, email, phone, genderDesc, dob, memberSince } = props.info
	return (
		<div className="bg-white mb-6 shadow-md">
			<div className="flex">
				<div className="w-2/12">
					<div className="flex justify-center items-center flex-col h-full">
						{profilePhoto ? (
							<img
								className="rounded-full h-24 w-24 flex items-center justify-center"
								src={profilePhoto}
								alt="profile"
							/>
						) : (
							<img
								className="rounded-full h-24 w-24 flex items-center justify-center"
								src={photoplaceholder}
								alt="profile"
							/>
						)}
					</div>
				</div>
				<div className="w-5/12 my-auto">
					<div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">
									Full Name
								</div>
								<div className="flex-1 font-bold">Janto Motulo</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Email</div>
								<div className="flex-1 font-bold">
									motulojanto@gmail.com
								</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">
									Phone Number
								</div>
								<div className="flex-1 font-bold">08212131616</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-5/12 my-auto">
					<div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Gender</div>
								<div className="flex-1 font-bold">Male</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">
									Birthday
								</div>
								<div className="flex-1 font-bold">
									{dob
										? moment(dob, 'YYYY-MM-DD').format('DD MMMM YYYY')
										: '7 February 1993'}
								</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">
									Member Since
								</div>
								<div className="flex-1 font-bold">
									{memberSince
										? moment(
												memberSince,
												'YYYY-MM-DD HH:mm:ss'
										  ).format('DD MMMM YYYY')
										: '12 November 2019'}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function MemberDetails2(props) {
	const { birthplace, address, city, homeNumber } = props.info
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Birthplace</div>
					<div className="w-1/2 font-bold">
						{birthplace ? birthplace : 'Tomohon'}
					</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Address</div>
					<div className="w-1/2 font-bold">
						{address ? address : 'Kel.Woloan Satu, Ling.VII, Kec. Tomohon Barat'}
					</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">City</div>
					<div className="w-1/2 font-bold">{city ? city : 'Tomohon'}</div>
				</div>
				<div className="flex">
					<div className="w-1/2 text-grayBold font-bold">Home Number</div>
					<div className="w-1/2 font-bold">{homeNumber ? homeNumber : '-'}</div>
				</div>
			</div>
		</div>
	)
}

function MemberDetails3(props) {
	const { baptismChurch, baptismCity, baptismDate } = props.info
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Baptism Church</div>
					<div className="w-1/2 font-bold">
						{baptismChurch ? baptismChurch : 'GMIM SION WOLOAN'}
					</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Baptism City</div>
					<div className="w-1/2 font-bold">
						{baptismCity ? baptismCity : 'Tomohon'}
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2 text-grayBold font-bold">Baptism Date</div>
					<div className="w-1/2 font-bold">
						{baptismDate
							? moment(baptismDate, 'YYYY-MM-DD').format('DD MMMM YYYY')
							: '-'}
					</div>
				</div>
			</div>
		</div>
	)
}

function MemberDetails4(props) {
	const { lastEdu, fieldEdu, occupation, fieldJob } = props.info
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Last Education</div>
					<div className="w-1/2 font-bold">
						{lastEdu ? lastEdu : 'Bachelor Degree'}
					</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Field of Education</div>
					<div className="w-1/2 font-bold">
						{fieldEdu ? fieldEdu : 'Computer Science'}
					</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Occupation</div>
					<div className="w-1/2 font-bold">
						{occupation ? occupation : 'Software Developer'}
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2 text-grayBold font-bold">Field of Job</div>
					<div className="w-1/2 font-bold">{fieldJob ? fieldJob : 'Technology'}</div>
				</div>
			</div>
		</div>
	)
}

function MemberDetails5(props) {
	const { maritalStatus, spouse, childs } = props.info
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Marital Status</div>
				<div className="w-1/2 font-bold">
					{maritalStatus ? maritalStatus : 'Single'}
				</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Spouse Name</div>
				<div className="w-1/2 font-bold">
					{spouse ? spouse.fullname : 'Pevita Pearch'}
				</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Marital Date</div>
				<div className="w-1/2 font-bold">
					{spouse
						? spouse.weeding_date
							? moment(spouse.weeding_date, 'YYYY-MM-DD').format(
									'DD MMMM YYYY'
							  )
							: '-'
						: '-'}
				</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">1st Child</div>
				<div className="w-1/2 font-bold">
					{childs.length > 0 ? childs[0].fullname : 'Tony Stark'}
				</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Gender</div>
				<div className="w-1/2 font-bold">Male</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Birthday</div>
				<div className="w-1/2 font-bold">19 February 2016</div>
			</div>

			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">2nd Child</div>
				<div className="w-1/2 font-bold">
					{childs.length > 0 ? childs[0].fullname : 'Velove Vexia'}
				</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Gender</div>
				<div className="w-1/2 font-bold">Female</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Birthday</div>
				<div className="w-1/2 font-bold">24 February 2017</div>
			</div>
		</div>
	)
}
