import React from 'react'
import Flatpickr from 'react-flatpickr'

export function InputTime({ name, label, handleChange, value, errors }) {
	return (
		<div>
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<Flatpickr
				value={value}
				name={name}
				className="appearance-none border-2 rounded-lg w-full py-2 px-3 focus:outline-none border-secondary"
				onChange={handleChange}
				onClose={handleChange}
				placeholder="Choose Time"
				options={{
					enableTime: true,
					noCalendar: true,
					time_24hr: true,
					altInput: false,
				}}
			/>

			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}
