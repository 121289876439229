import React, { useState } from 'react'
import Modal from 'react-modal'
import { Button } from 'components/commons/Button'
import SelectOptions from 'components/commons/SelectOptions'
import { TextInput } from 'components/commons/TextInput'

const customStyles = {
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '270px',
		width: '550px',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#4da5dc',
		padding: '0px',
		margin: '0px',
		overflow: 'hidden',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

function TransferMember() {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	return (
		<div>
			<Button handleClick={handleToggleModal} type={'tertiary'}>
				Transfer Member
			</Button>

			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Transfer Member Modal"
				appElement={document.getElementById('root')}
			>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center mt-2 text-primary pl-2 text-md font-bold active:bg-red-700">
						Transfer Member
					</div>
					<div
						className="flex justify-center mt-1 text-primary pr-2 cursor-pointer text-xl font-bold"
						onClick={handleToggleModal}
					>
						X
					</div>
				</div>
				<div className="bg-gray-200 p-4 pb-4 w-full">
					<div className="mb-2">
						<SelectOptions label={'Choose Campus'} options={[]} />
					</div>
					<div className="mt-2">
						<TextInput label={'Search Small Group'} />
					</div>
				</div>
				<div className="flex bg-primary h-10 inline-block cursor-pointer justify-center">
					<span className="mt-auto mb-auto text-center font-bold text-white">
						Apply Filter
					</span>
				</div>
			</Modal>
		</div>
	)
}

export default TransferMember
