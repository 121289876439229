import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import { exportAttendance } from 'libs/apis/smallGroup'
import optionsMonth from 'services/optionsMonth'
import optionsYear from 'services/optionsYear'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'

function AppointmentDetails(props) {
	const [state, setState] = useState({
		months: optionsMonth(),
		years: optionsYear(),
		monthSelected: '1',
		yearSelected: moment().year(),
		data: [
			{
				id: 1,
				name: 'Cath',
				role: 'Member',
				attendee: false,
			},
			{
				id: 2,
				name: 'Kez',
				role: 'Member',
				attendee: true,
			},
			{
				id: 3,
				name: 'Maiki',
				role: 'Member',
				attendee: true,
			},
			{
				id: 4,
				name: 'Maiki',
				role: 'Member',
				attendee: true,
			},
			{
				id: 5,
				name: 'Maiki',
				role: 'Member',
				attendee: true,
			},
		],
	})

	const [isExport, setIsExport] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	// useEffect(() => {
	// 	const fetchAttendanceDetails = async () => {
	// 		try {
	// 			setIsLoading(true)
	// 			let res = await getDetailAttendance(props.match.params.id)

	// 			if (res.data.message) {
	// 				setState((prev) => (prev.data = []))
	// 				setIsLoading(false)
	// 			} else {
	// 				failedMessage()
	// 				setIsLoading(false)
	// 			}
	// 		} catch (error) {
	// 			console.log(error)
	// 			setIsLoading(false)
	// 		}
	// 	}

	// 	fetchAttendanceDetails()
	// }, [props.match.params.id])

	async function handleExport() {
		const { monthSelected, yearSelected } = state
		try {
			setIsExport(false)
			const response = await exportAttendance(monthSelected, yearSelected)

			if (response.data.api_status) {
				const link = document.createElement('a')
				link.href = response.data.data
				document.body.appendChild(link)
				link.click()
				setIsExport(false)
			} else {
				setIsExport(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	function handleChange({ currentTarget: input }) {
		setState({ [input.name]: input.value })
	}

	const breads = [
		{ url: '/small-group/list', title: 'List of All Groups' },
		{ url: '/small-group/view', title: 'View Group' },
		{ url: '/smallgroup/attendance', title: 'View Attendance' },
		{ url: '', title: 'View Details' },
	]

	const { months, monthSelected, years, yearSelected, data } = state

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>View Details</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="View Details" breads={breads} />
			{isLoading ? (
				<Loading />
			) : (
				<div className="flex justify-between">
					<AppointmentDescription />
					<AttendeeList data={data} />
				</div>
			)}
		</div>
	)
}

export default AppointmentDetails

const AppointmentDescription = () => {
	return (
		<div className="bg-white shadow-md w-1/3 rounded" style={{ height: '450px' }}>
			<div className="pl-6">
				<h1 className="text-bold mb-4">Appointment Title</h1>
				<div className="mb-4">
					<label className="text-sm font-bold">Type</label>
					<p className="text-sm mb-4">Type A</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Date</label>
					<p className="text-sm mb-4">7 February 2020</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Time</label>
					<p className="text-sm mb-4">Created By</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Attendee</label>
					<p className="text-sm mb-4">
						<span>12</span>/<span>15</span>
					</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Notes</label>
					<p className="text-sm mb-4">
						Dont forget to use black and white attire for the celebration
					</p>
				</div>
			</div>
		</div>
	)
}

const AttendeeList = ({ data }) => {
	return (
		<table className="bg-white shadow-md w-3/5 rounded">
			<thead>
				<tr className="text-center">
					<th className="px-4 py-4">
						TYPE
						<i className="fas fa-sort ml-1 sortby cursor-pointer" onClick />
					</th>
					<th className="px-4 py-4">
						ROLE
						<i className="fas fa-sort ml-1 sortby cursor-pointer" onClick />
					</th>
					<th className="px-4 py-4">
						DATE
						<i className="fas fa-sort ml-1 sortby cursor-pointer" onClick />
					</th>
				</tr>
			</thead>
			<tbody>
				{data.map((val) => (
					<tr className="text-center" style={{ borderTop: '3px solid #f8f9fb' }}>
						<td className="px-4 py-4">{val.name}</td>
						<td className="px-4 py-4">{val.role}</td>
						<td className="px-4 py-4">
							<input
								type="radio"
								className="form-radio text-primary border-2"
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
