import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

export function InputDate({ name, label, value, handleChange, withSec, errors }) {
	let optionsWithsec = {
		dateFormat: 'd/m/Y H:i',
		altInput: true,
		altFormat: 'd M Y H:i',
		enableTime: true,
		time_24hr: true,
		defaultHour: moment().format('HH'),
		defaultMinute: moment().format('mm'),
		minDate: 'today',
	}
	let optionsNosec = {
		dateFormat: 'd/m/Y',
		altFormat: 'd M Y',
		enableTime: false,
		altInput: true,
		minDate: 'today',
	}
	return (
		<div>
			{label && (
				<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			)}
			<div className="flex justify-between bg-white border-2 rounded-lg p-3 leading-tight focus:outline-none">
				<Flatpickr
					name={name}
					value={value}
					onChange={handleChange}
					options={withSec ? optionsWithsec : optionsNosec}
					placeholder="Choose date"
				/>
				<div className="input-group-prepend">
					<i className="far fa-calendar-alt" onClick={handleChange} />
				</div>
			</div>

			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}
