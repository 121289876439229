import React, { Component } from 'react'
import Loading from 'components/commons/Loading'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import DevotionTable from 'components/devotions/DevotionTable'
import Pagination from 'components/commons/Pagination'
import { ReactComponent as DevIcon } from 'assets/icons/devotion.svg'
import { devotionList, devotionArchive, devotionDelete } from 'services/devotionServices'
import Searchbar from 'components/commons/Searchbar'
import alert from 'services/alert'
import Select from 'components/commons/Select'

class Devotions extends Component {
	state = {
		filterKeyword: '',
		filterStatus: '',
		currentPage: 1,
		lastPage: 1,
		sortColumn: { path: '', order: '' },
		isLoading: false,
		devotions: [],
	}

	doLoad = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const { currentPage, filterStatus, filterKeyword, sortColumn } = this.state
				const {
					data: { data: result },
				} = await devotionList(
					currentPage,
					filterStatus,
					filterKeyword,
					sortColumn.path,
					sortColumn.order
				)
				this.setState({
					isLoading: false,
					devotions: result.data,
					currentPage: result.current_page,
					lastPage: result.last_page,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
			}
		})
	}

	componentDidMount() {
		this.doLoad()
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.doLoad()
		})
	}

	handleSearch = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value, currentPage: 1 }, () => {
			this.doLoad()
		})
	}

	handleDelete = async (devotion) => {
		const { value } = await alert.confirmDelete()
		if (value) {
			this.setState({ isLoading: true }, async () => {
				try {
					const { data } = await devotionDelete(devotion.devotion_id)
					if (data.api_status) {
						const { currentPage, lastPage, devotions } = this.state
						if (currentPage === lastPage && devotions.length === 1) {
							this.setState({ currentPage: currentPage - 1 })
						}
						this.doLoad()
					} else {
						this.setState({ isLoading: false })
					}
				} catch (error) {}
			})
		}
	}

	handleArchive = async (devotion) => {
		const { value } = await alert.confirmArchive()
		if (value) {
			this.setState({ isLoading: true }, async () => {
				try {
					const { data } = await devotionArchive(devotion.devotion_id)
					if (data.api_status) {
						const devotions = [...this.state.devotions]
						const index = devotions.indexOf(devotion)
						devotions[index] = { ...devotions[index] }
						devotions[index].status = 'Archived'
						this.setState({ devotions })
					}
					this.setState({ isLoading: false })
				} catch (error) {}
			})
		}
	}

	handleSort = (sortColumn) => {
		this.setState({ sortColumn }, () => {
			this.doLoad()
		})
	}

	renderData = () => {
		const { length: count } = this.state.devotions
		const { currentPage, lastPage, devotions, isLoading, sortColumn } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div>
				<DevotionTable
					devotions={devotions}
					sortColumn={sortColumn}
					onSort={this.handleSort}
					onArchive={this.handleArchive}
					onDelete={this.handleDelete}
				/>
				<Pagination
					currentPage={currentPage}
					lastPage={lastPage}
					onPageChange={this.handlePageChange}
				/>
			</div>
		)
	}

	render() {
		const name = JSON.parse(localStorage.getItem('church')).church_name
			? JSON.parse(localStorage.getItem('church')).church_name
			: '-'
		const breads = [{ key: 'listofall', label: 'List of All' }]
		const options = [
			{ value: 'Published', show_as: 'Published' },
			{ value: 'Draft', show_as: 'Draft' },
			{ value: 'Archived', show_as: 'Archived' },
		]
		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<HeadTitle title={`${name} - Devotion`} />
				<BreadCrumb title="List of All" breads={breads} icon={DevIcon} />
				<div className="flex mt-4 mb-8">
					<Searchbar
						filterKeyword={this.state.filterKeyword}
						handleOnSearch={this.handleSearch}
					/>
					<Select
						name="filterStatus"
						value={this.state.filterStatus}
						onChange={this.handleSearch}
						options={options}
					/>
				</div>
				{this.renderData()}
			</div>
		)
	}
}

export default Devotions
