import React, { useState, useCallback } from 'react'
import Modal from 'react-modal'
import Flatpickr from 'react-flatpickr'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '82%',
		height: '53%',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

function AppointmentsExport() {
	const [openModal, setOpenModal] = useState(false)
	const [tab, setTab] = useState('period')
	const [roles, setRoles] = useState([
		'role 1',
		'role 2',
		'role 3',
		'role 4',
		'role 5',
		'role 6',
	])

	const handleToggleModal = () => {
		setOpenModal(!openModal)
	}

	const handleOnTab = useCallback(
		(event) => {
			setTab(event.target.value)
			// setActive(!active)
		},
		[setTab]
	)

	const periodTab = tab === 'period'
	const campusTab = tab === 'campus'
	const smallGroupTab = tab === 'smallGroup'
	const appointmentTab = tab === 'appointment'
	const levelTab = tab === 'level'

	return (
		<div>
			<button onClick={handleToggleModal}>Export</button>
			<Modal
				isOpen={openModal}
				style={customStyles}
				contentLabel="Small Group Export Modal"
				appElement={document.getElementById('root')}
				onRequestClose={handleToggleModal}
			>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center text-primary pl-2 text-xl font-bold active:bg-red-700">
						Export
					</div>
					<div
						className="flex justify-center text-primary pr-2 cursor-pointer text-xl font-bold"
						onClick={handleToggleModal}
					>
						X
					</div>
				</div>
				<div className="flex bg-gray-200 pb-4">
					<div className="flex flex-col justify-between">
						<input
							className={`${
								tab === 'period'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'period'}
							onClick={handleOnTab}
						/>
						<input
							className={`${
								tab === 'campus'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'campus'}
							onClick={handleOnTab}
						/>
						<input
							className={`${
								tab === 'smallGroup'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'smallGroup'}
							onClick={handleOnTab}
						/>
						<input
							className={`${
								tab === 'appointment'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'appointment'}
							onClick={handleOnTab}
						/>
						<input
							className={`${
								tab === 'level'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-secondary border-2'
							} py-2 m-2 w-32 rounded-r-lg`}
							type="button"
							value={'level'}
							onClick={handleOnTab}
						/>
					</div>
					<div className="border-2 red mx-4 mt-2 h-auto" />
					{periodTab && (
						<div className="flex pt-2 w-full">
							<div className="inline-block w-1/2">
								<div className="shadow-md flex justify-between rounded-lg bg-white px-3 py-2 mr-6">
									<div className="input-group-prepend">From</div>
									<Flatpickr
										className="w-full text-center"
										options={{
											dateFormat: 'YYYY-MM-DD',
											altInput: true,
											altFormat: 'd M Y',
											enableTime: false,
											minDate: 'today',
										}}
									/>
									<div className="input-group-prepend">
										<i className="far fa-calendar-alt" />
									</div>
								</div>
							</div>
							<div className="inline-block w-1/2">
								<div className="shadow-md flex justify-between rounded-lg bg-white px-3 py-2 mr-6">
									<div className="input-group-prepend">To</div>
									<Flatpickr
										className="w-full text-center"
										options={{
											dateFormat: 'YYYY-MM-DD',
											altInput: true,
											altFormat: 'd M Y',
											enableTime: false,
											minDate: 'today',
										}}
									/>
									<div className="input-group-prepend">
										<i className="far fa-calendar-alt" />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="flex justify-between">
					<div className="flex w-1/2 bg-grey-300 h-10 inline-block cursor-pointer justify-center">
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Cancel
						</span>
					</div>
					<div className="flex w-1/2 bg-primary h-10 inline-block cursor-pointer justify-center">
						<span className="mt-auto mb-auto text-center font-bold text-white">
							Export
						</span>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default AppointmentsExport
