import * as Yup from 'yup'

const validationSchema = () =>
	Yup.object().shape({
		smallGroupLevel: Yup.string().required('Required'),
		parentSmallGroup: Yup.string().required('Required'),
		category: Yup.string().required('Required'),
		campus: Yup.string().required('Required'),
		name: Yup.string().required('Required'),
		ageFromTo: Yup.string().required('Required'),
		status: Yup.string().required('Required'),
		leader: Yup.string().nullable().required('Required'),
		description: Yup.string().required('Required'),
		day: Yup.string().required('Required'),
		time: Yup.string().required('Required'),
		established_date: Yup.string().required('Required'),
		fullAddress: Yup.string().required('Required'),
		shortAddress: Yup.string().required('Required'),
		region: Yup.string().required('Required'),
		postalCode: Yup.string().required('Required'),
		smallGroupUpperLevelLeaderName: Yup.string().required('Required'),
	})

export default validationSchema
