import React from 'react'
import { Link } from 'react-router-dom'
import Joi from 'joi-browser'
import ErrorMessage from 'components/commons/ErrorMessage'
import Form from 'components/commons/form/Form'
import HeadTitle from 'components/commons/HeadTitle'
import InputEmail from 'components/commons/InputEmail'
import InputPassword from 'components/commons/InputPassword'
import Loading from 'components/commons/Loading'
import logo from 'assets/icons/logo.svg'
import { loginUser } from 'services/authServices'
import alert from 'services/alert'

class LoginForm extends Form {
	state = {
		data: { email: '', password: '' },
		errors: {},
		isShowPassword: false,
		isRememberMe: false,
		isSubmit: false,
		errorMessage: '',
	}

	schema = {
		email: Joi.string().email({ minDomainSegments: 2 }).label('Email'),
		password: Joi.string().required(),
	}

	handleCheck = () => {
		this.setState({ isRememberMe: !this.state.isRememberMe })
	}

	handlePassword = () => {
		this.setState({ isShowPassword: !this.state.isShowPassword })
	}

	doSubmit = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { email, password } = this.state.data
				const isRememberMe = this.state.isRememberMe ? 1 : 0
				const { data } = await loginUser(email, password, isRememberMe)
				if (data.api_status) {
					await localStorage.setItem('church', JSON.stringify(data.data.church))
					await localStorage.setItem('token', data.data.token)
					await localStorage.setItem('user', JSON.stringify(data.data.user))
					await localStorage.setItem(
						'access_list',
						JSON.stringify(data.data.access_list)
					)
					this.props.history.replace('/')
				} else {
					this.setState({ errorMessage: data.message, isSubmit: false })
				}
			} catch (error) {
				this.setState({ ...this.state, isSubmit: false })
				alert.networkError()
			}
		})
	}

	render() {
		const { errors, isRememberMe, isShowPassword, errorMessage, isSubmit } = this.state
		const { email, password } = this.state.data

		return (
			<div className="h-screen">
				<HeadTitle title="Skyportal | Login" />
				<div className="container auth-container">
					<div className="md:w-1/2">
						<div className="flex justify-center">
							<img src={logo} alt="skyporter logo" className="w-16" />
						</div>
						<h3 className="auth-title">Welcome Back</h3>
						{errorMessage && (
							<div className="mb-3">
								<ErrorMessage message={errorMessage} />
							</div>
						)}
						<form onSubmit={this.handleSubmit}>
							<InputEmail
								name="email"
								value={email}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								onChange={this.handleChange}
								error={errors.email}
								placeholder="your@email.com"
							/>
							<InputPassword
								type={isShowPassword ? 'text' : 'password'}
								name="password"
								value={password}
								placeholder="your password"
								onChange={this.handleChange}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								isShow={isShowPassword}
								onClick={this.handlePassword}
								error={errors.password}
							/>
							<label className="inline-flex items-center font-bold mb-6">
								<input
									type="checkbox"
									className={`form-checkbox border-2 focus:shadow-none ${
										isRememberMe
											? 'border-primary'
											: 'border-secondary'
									}`}
									name="isRememberMe"
									onChange={this.handleCheck}
									checked={isRememberMe}
									value={isRememberMe}
								/>
								<span className="ml-2">Remember me</span>
							</label>
							<button
								disabled={isSubmit}
								className="bg-primary text-white flex w-full py-5 px-3 justify-center rounded-lg focus:outline-none"
								onClick={this.handleSubmit}
							>
								{isSubmit ? <Loading secondary /> : 'Login'}
							</button>
						</form>
						<div className="mt-6 flex justify-end">
							<Link to="/forgot-password" className="text-primary">
								Forgot password?
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default LoginForm
