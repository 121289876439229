import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import { getSmallGroupList, smallGroupExport } from 'libs/apis/smallGroup'
import { failedFetch } from 'libs/utils/messages'
import { closeSmallGroup } from 'libs/apis/smallGroup'
import { confirmCloseGroup } from 'libs/utils/confirmation'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import SmallGroupTable from 'components/smallGroup/SmallGroupTable'
import PaginationPage from 'components/commons/PaginationPage'
import SmallGroupFilter from 'components/smallGroup/SmallGroupFilter'
import Searchbar from 'components/commons/Searchbar'
import ButtonExport from 'components/commons/ButtonExport'
import { SmallGroupHeader } from './styles'

// import { findSmallGroupById, closeSmallGroup, updateData } from 'libs/utils/smallgroup'
// import Pagination from "components/commons/pagination";

function ListSmallGroup(props) {
	const [listSmallGroup, setListSmallGroup] = useState([
		{
			id: 1,
			campus: 'CAMPUS A',
			sgName: 'Small Group 1',
			sgLevel: 'Small Group',
			sgCategory: 'General',
			sgLeaders: 'Leader Name A',
			members: 10,
			day: 'Wednesday',
			time: '',
			status: 'open',
		},
		{
			id: 2,
			campus: 'CAMPUS B',
			sgName: 'Small Group 2',
			sgLevel: 'Facilitator',
			sgCategory: '-',
			sgLeaders: 'Leader Name B',
			members: 10,
			day: 'Wednesday',
			time: '',
			status: 'open',
		},
		{
			id: 3,
			campus: 'CAMPUS C',
			sgName: 'Small Group 3',
			sgLevel: 'Head Facilitator',
			sgCategory: '-',
			sgLeaders: 'Leader Name C',
			members: 10,
			day: 'Wednesday',
			time: '',
			status: 'open',
		},
		{
			id: 4,
			campus: 'CAMPUS D',
			sgName: 'Small Group 4',
			sgLevel: 'Small Group',
			sgCategory: 'General',
			sgLeaders: 'Leader Name D',
			members: 10,
			day: 'Friday',
			time: '',
			status: 'open',
		},
		{
			id: 5,
			campus: 'CAMPUS E',
			sgName: 'Small Group 5',
			sgLevel: 'Small Group',
			sgCategory: 'General',
			sgLeaders: 'Leader Name E',
			members: 10,
			day: 'Tuesday',
			time: '',
			status: 'open',
		},
		{
			id: 6,
			campus: 'CAMPUS F',
			sgName: 'Small Group 6',
			sgLevel: 'Small Group',
			sgCategory: 'General',
			sgLeaders: 'Leader Name F',
			members: 10,
			day: 'Wednesday',
			time: '',
			status: 'open',
		},
	])
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [sortBy, setSortBy] = useState({
		sgCampus: '',
		sgName: '',
		sgLevel: '',
		sgCategory: '',
		sgLeaders: '',
		sgMembers: '',
		sgDay: '',
		sgTime: '',
		sgStatus: '',
	})
	const [filter, setFilter] = useState({
		location: [],
		day: [],
		category: [],
		sgLevel: [],
		campus: [],
		keyword: '',
	})
	const [isActive, setIsActive] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [isExport, setIsExport] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const handleFetchData = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const { search } = this.props.location

				const page = search ? queryString.parse(search).page : 0
				const {
					filterKeyword,
					filterCategory,
					filterDay,
					filterTime,
					filterStatus,
					orderBy,
					orderDir,
				} = this.state
				const response = await getSmallGroupList(
					page,
					filterKeyword,
					filterCategory,
					filterDay,
					filterTime,
					filterStatus,
					orderBy,
					orderDir
				)

				if (response.data.api_status) {
					this.setState({
						isLoading: false,
						dataSmallGroups: response.data.data.data,
						currentPage: response.data.data.current_page,
						lastPage: response.data.data.last_page,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	// componentDidMount() {
	// 	this.LoadData()
	// }

	// componentDidUpdate(prevProps) {
	// 	const { location } = this.props
	// 	if (location.search && location.search !== prevProps.location.search) {
	// 		this.LoadData()
	// 	}
	// }

	const handleOnChangeTime = (time) => {
		setFilter({ ...filter, time: time })
	}

	const handleSort = (sort) => {
		if (filter.orderBy !== sort) {
			setFilter({ ...filter, orderBy: 'asc' })
		} else {
			if (filter.orderDir === 'asc') {
				setFilter({ ...filter, orderBy: sort, orderDir: 'desc' })
			} else if (filter.orderDir === 'desc') {
				setFilter({ ...filter, orderBy: sort, orderDir: 'asc' })
			}
		}
	}

	const handleOnExport = async () => {
		setIsExport(true)
		const response = await smallGroupExport(
			filter.keyword,
			filter.category,
			filter.day,
			filter.time,
			filter.status,
			filter.orderBy,
			filter.orderDir
		)
		if (response.data.api_status) {
			const link = document.createElement('a')
			link.href = response.data.data
			document.body.appendChild(link)
			link.click()
			setIsExport(false)
		}
	}

	const handleOnClose = async (id) => {
		const confirm = await confirmCloseGroup()
		if (confirm.value) {
			setIsLoading(true)
			try {
				const response = await closeSmallGroup(id)
				if (response.data.api_status) {
					handleFetchData()
				}
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						setIsRedirect(true)
					} else {
						setIsLoading(false)
					}
				}
				console.log(error)
			}
		}
	}

	const funcFilter = (array, object, value, name, callback) => {
		if (array.includes(value)) {
			let selectFilter = array.filter((val) => val !== value)
			callback({ ...object, [name]: selectFilter })
		} else {
			callback({ ...object, [name]: [...array, value] })
		}
	}

	const handleSelectFilter = (event) => {
		switch (event.target.name) {
			case 'location':
				funcFilter(
					filter.location,
					filter,
					event.target.value,
					event.target.name,
					setFilter
				)
				break

			case 'day':
				funcFilter(filter.day, filter, event.target.value, event.target.name, setFilter)
				break

			case 'category':
				funcFilter(
					filter.category,
					filter,
					event.target.value,
					event.target.name,
					setFilter
				)
				break

			case 'sgLevel':
				funcFilter(
					filter.sgLevel,
					filter,
					event.target.value,
					event.target.name,
					setFilter
				)

				break

			case 'campus':
				funcFilter(
					filter.campus,
					filter,
					event.target.value,
					event.target.name,
					setFilter
				)
				break

			default:
				break
		}
	}

	const handleApplyFilter = async () => {
		// setIsLoading(true)
		// try {
		// 	const response = await getSmallGroupList(1, filter.keyword, filter.category, filter.day, filter.time, filter.status, filter.orderBy, filter.orderDir)
		// 	if (response.data.api_status) {
		// 		setIsLoading(false)
		// 		setListSmallGroup(response.data.data.data)
		// 		setCurrentPage(response.data.data.current_page)
		// 		setLastPage(response.data.data.last_page)
		// 	} else {
		// 		setIsLoading(false)
		// 		setErrorMessage(failedFetch)
		// 	}
		// } catch (error) {
		// 	console.log(error)
		// 	setIsLoading(false)
		// }
		console.log(filter, 'filter')
	}

	const handleClearFilter = () => {
		setFilter({ ...filter, location: [], day: [], category: [], sgLevel: [], campus: [] })
	}

	const handleOnSearch = (event) => {
		setFilter({ ...filter, keyword: event.target.value })
	}

	const { location } = props
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`

	const displayLoading = isLoading && !errorMessage
	const displayError = !isLoading && errorMessage
	const displayNoData = !isLoading && !errorMessage && listSmallGroup.length === 0
	const displayData = !isLoading && !errorMessage && listSmallGroup.length > 0
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of All Groups' }]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Small Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="List of All Groups" breads={breads} />
			<SmallGroupHeader>
				<SmallGroupFilter
					handleSelectFilter={handleSelectFilter}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					filter={filter}
				/>
				<ButtonExport style="ml-16">Export</ButtonExport>
				<Searchbar filterKeyword={filter.keyword} handleOnSearch={handleOnSearch} />
			</SmallGroupHeader>
			{displayLoading && <Loading />}
			{displayError && <div className="text-center">{errorMessage}</div>}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayData && (
				<div>
					<SmallGroupTable
						listSmallGroup={listSmallGroup}
						handleOnSort
						handleOnClose={handleOnClose}
					/>
					<PaginationPage
						currentPage={currentPage}
						lastPage={lastPage}
						activeLink={active}
						length={listSmallGroup.length}
						url={location.pathname}
					/>
				</div>
			)}
		</div>
	)
}

export default ListSmallGroup
