import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as SmallGroupIcon } from 'assets/icons/small-group.svg'

const navigation = [
	'/small-group/create',
	'/small-group/list',
	'/small-group/edit/:id',
	'/small-group/view/:id',
	'/small-group/request/:id',
	'/small-group/view-member/:sgId/:id',
	'/small-group/smallgroup-tree/:id',
	'/small-group/view/:id',
	'/smallgroup/attendance/:id',
	'/smallgroup/attendance-detail/:id',
]

class SmallGroupMenu extends Component {
	state = { isOpenSub: false }

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = navigation.filter((menu) => menu === path)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state

		const menus = navigation.filter((menu) => menu === path)
		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkCreate = `${
			path === '/small-group/create' ? 'text-primary' : 'text-gray-800'
		}`

		const styleLinkTree = `${
			path === '/small-group/smallgroup-tree/:id' ? 'text-primary' : 'text-gray-800'
		}`

		const styleLinkAppointments = `${
			path === '/small-group/list-appointments' ? 'text-primary' : 'text-gray-800'
		}`

		const styleLinkList = `${
			path === '/small-group/list' ||
			path === '/small-group/view/:id' ||
			path === '/small-group/edit/:id' ||
			path === '/small-group/request/:id' ||
			path === '/small-group/view-member/:sgId/:id' ||
			path === '/smallgroup/attendance/:id' ||
			path === '/smallgroup/attendance-detail/:id'
				? 'text-primary'
				: 'text-gray-800'
		}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<SmallGroupIcon className={styleIcon} />
					</div>
					<div>Small Group</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/small-group/create" className={styleLinkCreate}>
									Create Group
								</Link>
							</li>
							<li className="mb-4">
								<Link
									to="/small-group/smallgroup-tree/:id"
									className={styleLinkTree}
								>
									Tree
								</Link>
							</li>
							<li className="mb-4">
								<Link
									to="/small-group/list-appointments"
									className={styleLinkAppointments}
								>
									List of Appointments
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/small-group/list" className={styleLinkList}>
									List of All Groups
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default SmallGroupMenu
