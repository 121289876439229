import styled from 'styled-components'

export const ListSGContainer = styled.div`
	display: grid;
	grid-gap: 20px;
`

export const RoleRibbon = styled.span`
	color: ${(props) => (props.value ? 'white' : 'white')};
	position: relative;
	top: ${(props) => (props.value ? '-60px' : '-65px')};
	width: 0;
	left: 20px;
	text-align: center;
	font-size: 18px;
	font-family: arial;
	display: block;
	transform: rotate(-45deg);
`

export const RoleContainer = styled.div`
	width: 0;
	height: 0;
	border-top: 10px solid;
	border-bottom: 10px solid transparent;
	border-right: 90px solid transparent;
	margin-bottom: -100px;
	border-top: 90px solid ${(props) => (props.value ? '#1f3c88' : '#5893d4')};
`

export const SmallGroupHeader = styled.div`
	display: flex;
	width: 100%;

	div:last-of-type {
		margin-left: auto;
	}
`
