import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'

function SmallGroupTree() {
	const [headDateFacilitator, setHeadDateFacilitator] = useState([
		{
			id: 1,
			hdfName: [
				{
					id: 1,
					name: 'hdf 1',
				},
				{
					id: 2,
					name: 'hdf 2',
				},
			],
		},
		{
			id: 2,
			hdfName: [
				{
					id: 1,
					name: 'hdf 1',
				},
				{
					id: 2,
					name: 'hdf 2',
				},
			],
		},
	])
	const [dateFacilitator, setDateFacilitator] = useState([])
	const [dateLeader, setDateLeader] = useState([])
	const [dateMember, setDateMember] = useState([])
	const [openDate, setOpenDate] = useState('')
	const [tab, setTab] = useState('All')

	const handleOpenDF = (event) => {
		let found = headDateFacilitator.find((hdf) => hdf.id === parseInt(event.target.id))

		if (found.id === parseInt(event.target.id)) {
			if (openDate === found.id) {
				setOpenDate('')
				return
			}
			setOpenDate(found.id)
			setDateFacilitator([
				{
					id: 1,
					dfName: [
						{ id: 1, name: 'pevita' },
						{ id: 2, name: 'pearch' },
					],
				},
				{
					id: 2,
					dfName: [
						{ id: 1, name: 'janto' },
						{ id: 2, name: 'motulo' },
					],
				},
			])
		}
	}

	const handleChangeFilter = (event) => {
		setTab(event.target.value)
	}

	useEffect(() => {
		setDateFacilitator([
			{
				id: 1,
				dfName: [
					{ id: 1, name: 'pevita' },
					{ id: 2, name: 'pearch' },
				],
			},
			{
				id: 2,
				dfName: [
					{ id: 1, name: 'janto' },
					{ id: 2, name: 'motulo' },
				],
			},
		])
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Date Tree</title>
			</Helmet>
			<PageBreadcrumbs
				icon={SGIcon}
				title="Date Tree"
				breads={[{ url: '', title: 'Tree' }]}
			/>

			{headDateFacilitator.map((hdf) => (
				<>
					<HeadDateFacilitator
						key={hdf.id}
						id={hdf.id}
						handleClick={handleOpenDF}
						hdfData={hdf.hdfName}
						active={openDate}
					/>

					{openDate === hdf.id && (
						<>
							{dateFacilitator.map((df) => (
								<DateFacilitator
									key={df.id}
									id={df.id}
									dfData={df.dfName}
									active={openDate}
								/>
							))}
						</>
					)}
				</>
			))}
		</div>
	)
}

export default SmallGroupTree

const HeadDateFacilitator = ({ handleClick, id, hdfData, active }) => {
	return (
		<div
			className={`border-2 shadow-md flex justify-between rounded-full bg-white mt-2 hover:border-red-200 ${
				active === id ? '#fc9500' : 'white'
			}`}
		>
			<p className="rounded-l-full pl-4 pr-4 flex items-center font-bold text-white bg-red-200">
				HDF
			</p>
			<div className="ml-4 flex w-full " id={id} onClick={handleClick}>
				<div className="mt-4 mb-4">
					{hdfData.map((data) => (
						<Link
							className="hover:border-black border-b border-b-4 m-4 uppercase"
							to={`/small-group/view-member/${id}/${data.id}`}
						>
							{data.name}
						</Link>
					))}
				</div>
			</div>
		</div>
	)
}

const DateFacilitator = ({ handleClick, id, dfData, active }) => {
	return (
		<div className="ml-12 mt-2 border-2 border-white shadow-md flex bg-white justify-between rounded-full hover:border-pink">
			<div className="flex" id={id} onClick={handleClick}>
				<p className="rounded-l-full pl-4 pr-4 flex items-center font-bold text-white bg-pink">
					DF
				</p>
				<div className="mt-4 mb-4">
					{dfData.map((data) => (
						<Link
							className="uppercase m-6 hover:border-black border-b border-b-4 "
							to={`/small-group/view-member/${1}/${data.id}`}
						>
							{data.name}
						</Link>
					))}
				</div>
			</div>
		</div>
	)
}

const DateLeader = ({ handleClick, id, dlData, active }) => {
	return (
		<div className="mt-2 border-2 border-white shadow-md flex justify-between bg-white rounded-full ml-20 hover:border-purple">
			<div className="flex" onClick={handleClick} id={id}>
				<p className="rounded-l-full pl-4 pr-4 flex items-center font-bold text-white bg-purple">
					DL
				</p>
				<div className="mt-4 mb-4">
					<Link
						className="hover:border-black border-b border-b-4 m-6"
						to={`/small-group/view-member/${1}/${1}`}
					>
						DF NAME 2
					</Link>
					<span className="m-2">&</span>
					<Link
						className="hover:border-black border-b border-b-4 m-6"
						to={`/small-group/view-member/${1}/${2}`}
					>
						DF NAME 2
					</Link>
				</div>
			</div>
		</div>
	)
}

const DateMember = ({ dateMember }) => {
	return (
		<div className="mt-2 border-2 border-white shadow-md flex justify-between bg-white rounded-full ml-32 hover:border-skyblue">
			<div className="flex">
				<p
					className="rounded-l-full pl-4 pr-4 flex items-center font-bold text-white"
					style={{ backgroundColor: '#00a5b7' }}
				>
					CT
				</p>
				<div className="mt-4 mb-4">
					<Link
						className="uppercase m-6 hover:border-black border-b border-b-4 "
						to={`/small-group/view-member/${1}/${1}`}
					>
						MEMBER 1
					</Link>
				</div>
			</div>
		</div>
	)
}
