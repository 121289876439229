import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Tippy from '@tippy.js/react'

function SmallGroupTableRow(props) {
	const [open, setOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleToggle() {
		setOpen(!open)
	}

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setOpen(false)
	}

	function handleClose() {
		props.handleOnClose(this.props.id)
	}

	const {
		id,
		campus,
		sgName,
		sgLevel,
		sgCategory,
		sgLeaders,
		members,
		day,
		time,
		status,
	} = props

	const styleDropDownOpen = `shadow-md absolute right-20px ${open ? 'block' : 'hidden'}`
	const leaderEmpty = `${sgLeaders === null ? 'opacity-50' : 'opacity-100'}`
	const styleTdStatus = status ? 'relative mr-6' : 'absolute mr-6'

	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className={styleTdStatus}>
				{status === 'Closed' && (
					<Tippy content="Closed">
						<div className="status-post-tag">
							<div className="ribbon-black-triagle" />
						</div>
					</Tippy>
				)}
			</td>
			<td className="pl-4">
				<div className={leaderEmpty}>{campus}</div>
			</td>
			<td>
				<div className={leaderEmpty}>{sgName}</div>
			</td>
			<td>
				<div className={leaderEmpty}>{sgLevel}</div>
			</td>
			<td className="pl-10">
				<div className={leaderEmpty}>{sgCategory}</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={leaderEmpty}>{sgLeaders}</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={leaderEmpty}>{members}</div>
			</td>
			<td className="px-2 py-4 text-left">
				<div className={leaderEmpty}>{day}</div>
			</td>
			<td className="px-2 py-4 text-left">
				<div className={leaderEmpty}>
					{moment(time, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')}
				</div>
			</td>
			<td className="text-center px-2 py-4">
				<div className={leaderEmpty}>{status}</div>
			</td>
			<td className="px-2 py-4">
				{status !== 'Closed' && (
					<div className="cursor-pointer" onClick={handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={node} className="bg-white">
								<li>
									<Link
										className="px-4 py-2 block hover:bg-blueSecondary"
										to={`/small-group/view/${id}`}
									>
										View
									</Link>
								</li>
								<li>
									<Link
										className="px-4 py-2 block hover:bg-blueSecondary"
										to={`/small-group/edit/${id}`}
									>
										Edit
									</Link>
								</li>
								<li
									className="px-4 py-2 block hover:bg-blueSecondary"
									onClick={handleClose}
								>
									Close Group
								</li>
							</ul>
						</div>
					</div>
				)}
			</td>
		</tr>
	)
}

export default SmallGroupTableRow
