import { withFormik } from 'formik'
import SmallGroupForm from 'components/forms/SmallGroupForm'
import validationSchema from 'libs/utils/validationSchema'
// import { createSmallGroups } from 'libs/apis/smallGroup'
// import { successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'
// import moment from 'moment'

const SmallGroupFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		smallGroupLevel: props.smallGroupLevel || '',
		parentSmallGroup: props.parentSmallGroup || '',
		description: props.description || '',
		campus: props.campus || '',
		ageFromTo: props.ageFromTo || '',
		smallGroupUpperLevelLeaderName: props.smallGroupUpperLevelLeaderName || '',
		category: props.category || '',
		name: props.name || '',
		status: props.status || '',
		leader: props.leader || null,
		day: props.day || '',
		time: props.time || '',
		established_date: props.established_date || '',
		fullAddress: props.fullAddress || '',
		shortAddress: props.shortAddress || '',
		region: props.region || '',
		postalCode: props.postalCode || '',
	}),
	validationSchema: validationSchema,
	handleSubmit: async (values, { props, setFieldValue, setSubmitting }) => {
		// try {
		// 	const response = await createSmallGroups(values)
		// 	if (response.data.api_status) {
		// 		await successMessage()
		// 		props.history.push('/small-group/list')
		// 	} else {
		// 		await failedMessage(response.data.message)
		// 	}
		// } catch (error) {
		// 	await somethingWrong()
		// }
	},
})(SmallGroupForm)

export default SmallGroupFormik
