import React, { Component } from 'react'
import moment from 'moment'
import UsersItem from './UsersItem'
import { partial } from 'libs/utils/utils'

class UserItems extends Component {
	constructor() {
		super()
		this.state = {
			dropDownOpen: false,
		}
		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	handleToggle = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen })
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownOpen: false })
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node
	}

	render() {
		const {
			list,
			selectedData,
			handleSort,
			handleActive,
			handleOnChecked,
			handleDeactivate,
			handleCheckAll,
			handleCheckActive,
			handleCheckDeactive,
		} = this.props
		const handleSortLastActive = partial(handleSort, 'last_active_at')

		const { dropDownOpen } = this.state

		const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
			dropDownOpen ? 'block' : 'hidden'
		}`

		return (
			<div className="bg-white shadow-md rounded">
				<table className="w-full">
					<thead className="bt-blueSecondary border-2 border-solid">
						<tr>
							<th className="px-2 py-4">
								<input
									className="form-checkbox border-2 border-secondary h-5 w-5 text-primary"
									type="checkbox"
									value={selectedData.length > 0 ? true : false}
									checked={selectedData.length > 0 ? true : false}
									onChange={handleCheckAll}
								/>
							</th>
							<th className="px-2 py-4 text-left">NAME</th>
							<th className="px-2 py-4 text-left">EMAIL</th>
							<th className="px-2 py-4 text-left">PHONE</th>
							<th className="px-2 py-4 text-left">GENDER</th>
							<th className="px-2 py-4 text-left">ROLE</th>
							<th className="px-2 py-4 text-left">STATUS</th>
							<th className="px-2 py-4 flex">
								LAST ACTIVITY
								<i
									className="mt-4 fas fa-sort sortby cursor-pointer"
									onClick={handleSortLastActive}
								/>
							</th>
							<th className="px-2 py-4">
								<div className="cursor-pointer" onClick={this.handleToggle}>
									<i className="dropdown-trigger fas fa-ellipsis-v" />
									<div className={styleDropDownOpen}>
										<ul ref={this.setWrapperRef} className="bg-white">
											<li
												className="px-4 py-2 block hover:bg-blueSecondary"
												onClick={handleCheckActive}
											>
												Active User
											</li>
											<li
												className="px-4 py-2 block hover:bg-blueSecondary"
												onClick={handleCheckDeactive}
											>
												Deactivate User
											</li>
										</ul>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody className="border-2 border-solid">
						{list.map((item) => (
							<UsersItem
								checked={item.checked}
								key={item.user_id}
								id={item.user_id}
								name={item.user_profile.fullname}
								email={item.email}
								phone={item.phone_number}
								gender={
									item.user_profile.gender_desc
										? item.user_profile.gender_desc
										: 'Male'
								}
								role={item.role_name}
								status={item.is_active_desc}
								lastactive={
									item.last_active_at
										? moment(item.last_active_at).format(
												'DD MMM YYYY'
										  )
										: '-'
								}
								lastactivefrom={
									item.last_active_at
										? moment(item.last_active_at).fromNow()
										: '-'
								}
								handleOnChecked={handleOnChecked}
								handleActive={handleActive}
								handleDeactivate={handleDeactivate}
							/>
						))}
					</tbody>
				</table>
			</div>
		)
	}
}

export default UserItems
