import React from 'react'
import { Link } from 'react-router-dom'
import Joi from 'joi-browser'
import HeadTitle from 'components/commons/HeadTitle'
import Form from 'components/commons/form/Form'
import InputEmail from 'components/commons/InputEmail'
import Loading from 'components/commons/Loading'
import logo from 'assets/icons/logo.svg'
import { forgotPassword } from 'services/authServices'
import alert from 'services/alert'

class ForgotForm extends Form {
	state = {
		data: { email: '' },
		errors: {},
		isSubmit: false,
	}

	schema = {
		email: Joi.string().email({ minDomainSegments: 2 }).label('Email'),
	}

	doSubmit = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { data } = await forgotPassword(this.state.data.email)
				if (data.api_status) {
					alert.succeed('Success', 'Your email has been sent.')
				} else {
					alert.failed('Failed', data.message)
				}
			} catch (error) {
				// do something?
			}
			this.setState({ isSubmit: false })
		})
	}

	render() {
		const { data, errors, isSubmit } = this.state

		return (
			<div className="h-screen">
				<HeadTitle title="Skyportal | Forgot Password" />
				<div className="container auth-container">
					<div className="md:w-1/2">
						<div className="flex justify-center">
							<img src={logo} alt="skyporter logo" className="w-16" />
						</div>
						<h3 className="auth-title">
							Forgot Your Password?
							<p className="text-xs mt-2">
								We’ll send you an email with a reset link.
							</p>
						</h3>
						<form onSubmit={this.handleSubmit}>
							<InputEmail
								name="email"
								value={data.email}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								onChange={this.handleChange}
								error={errors.email}
								placeholder="your@email.com"
							/>
							<button
								disabled={isSubmit}
								className="bg-primary text-white flex w-full py-5 px-3 justify-center rounded-lg focus:outline-none"
								onClick={this.handleSubmit}
							>
								{isSubmit ? <Loading secondary /> : 'Send email'}
							</button>
						</form>
						<div className="mt-6 flex">
							<Link to="/login" className="text-primary">
								Back to Log in
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ForgotForm
